.horizontal-timeline {
  margin-top: 90px;
  display: flex;
  align-items: center;

  &__container {
    position: relative;
    width: 100%;
    height: 20px;
    display: flex;

    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      height: 2px;
      width: 100%;
      background-color: #f67c1f;
    }
  }

  &__item {
    position: relative;
    flex: 1 1 0;

    &:before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      background-color: white;
      border: 2px solid #f67c1f;
      border-radius: 100%;
      left: 50%;
      transform: translate(-50%, 25%);
    }

    & > div {
      position: absolute;
      width: 100%;
      text-align: center;
    }

    &_bottom {
      & > div {
        top: 100%;
      }
    }

    &_top {
      & > div {
        bottom: 100%;
      }
    }
  }

  &__gradient {
    &:before {
      background: linear-gradient(to right, #f67c1f 0, #f67c1f 80%, #fff 100%);
    }
  }
}