.cookie-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 99992;
}

.cookie-modal-settings {
    z-index: 99993;
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 30%;
    max-width: 35%;
    height: 65%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 5px;
    overflow: hidden;
}

.cookie-modal-settings div:nth-child(2) {
    height: 77%;
}

.cookie-modal-border {
    border-bottom: 1px solid #cccccc;
}

.cookie-modal-content {
    height: 85%;
    overflow-y: scroll;
}

.cookie-modal-content-row {
    display: grid;
    grid-template-columns: 1fr 7fr 4fr;
    align-items: center;
    margin: 1px 0;
    padding: 5px 3px;
    border-radius: 5px;
}

.cookie-border {
    margin-top: 2px;
    overflow-y: auto;
    border: 1px solid #cccccc;
}

.cookie-padding {
    padding: 10px 20px;
}

.cookie-text-header {
    color: #16224e !important;
    margin-bottom: 10px;
}

.cookie-button {
    position: fixed;
    bottom: 5px;
    right: 6px;
    font-size: 2rem;
    z-index: 300;
}

.cookie-modal-button {
    position: relative;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    border: none;
    font-family: Montserrat, serif;
    padding: 3px 5px;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: auto !important;
}

.cookie-modal {
    display: grid;
    grid-template-columns: 7fr 1fr 2fr 2fr;
    padding: 10px 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99994;
}

.cookie-modal button.ant-btn {
    width: 90% !important;
}

.cookie-name:first-letter {
    text-transform: uppercase
}

.cookie-buttons button {
    margin-left: 5px;
}

.ant-switch {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-switch-loading, .ant-switch-disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.ant-switch-checked {
    background-color: #1890ff;
}

.ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 7px;
}

.ant-switch-inner {
    display: block;
    margin: 0 7px 0 25px;
    color: #fff;
    font-size: 12px;
    transition: margin 0.2s;
}

.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 2px);
}
.ant-switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    transition: all 0.2s ease-in-out;
}

.ant-switch-handle::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    transition: all 0.2s ease-in-out;
    content: '';
}

[ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #1890ff;
    box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
    opacity: 0.2;
    -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    content: '';
    pointer-events: none;
}

@media screen and (max-width: 820px) {
    .cookie-modal-settings {
        width: 98%;
        max-width: 98%;
        height: 98%;
        font-size: 0.8rem;
    }

    .cookie-text-header {
        font-size: 0.9rem;
    }
}