.chosen-container .chosen-drop {
    position: absolute;
    top: 100%;
    z-index: 1010;
    width: 100%;
    border: 1px solid #aaa;
    background: #fff;
    -webkit-box-shadow: 0 4px 5px rgba(0,0,0,.15);
    box-shadow: 0 4px 5px rgba(0,0,0,.15);
    clip: rect(0,0,0,0);
    -webkit-clip-path: inset(100% 100%);
    clip-path: inset(100% 100%);
}

.chosen-container-single .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 100%;
}

.chosen-container-active.chosen-with-drop .chosen-single div b.open {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    -ms-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg)
}

.chosen-container.chosen-container-single.chosen-container-single-nosearch {
    cursor: pointer;
}

.chosen-container.chosen-with-drop .chosen-drop {
    clip: auto;
    -webkit-clip-path: none;
    clip-path: none;
}

.chosen-container-single .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 4px;
    white-space: nowrap;
}

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    clip: rect(0,0,0,0);
    -webkit-clip-path: inset(100% 100%);
    clip-path: inset(100% 100%);
}

.chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 5px 6px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none;
}
.chosen-container .chosen-results li:hover {
    color: #fff;
    background-color: #f67c1f;
}
