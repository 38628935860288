@media (max-width: 400px) {
    .cd-headline.letters.type {
        font-size: 24px;
    }

    .cd-words-wrapper {
        display: block;
    }

    .bottomLink.aos.aos-init {
        font-size: 17px;
    }

    .scrollDown {
        display: none;
    }

    .container {
        padding-top: 0;
        padding-bottom: 0;
    }
}