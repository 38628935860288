.footer {
  max-width: 1156px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  & a {
    color: #16224e;
    transition: 0.2s color ease;

    &:first-child {
      margin-bottom: 24px;
    }

    &:hover {
      color: #f67c1f;
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-links {
      display: flex;
      flex-direction: column;

      & a {
        font-size: 24px;
        letter-spacing: 1px;
      }
    }
  }

  &__docs {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    &-item {
      font-size: 12px;
    }
  }
}

@media (max-width: 400px) {
  .footer {
    &__navigation {
      flex-direction: column;

      &-logo {
        order: -1;
      }

      &-links {
        align-items: center;

        & a {
          font-size: 18px;
          margin-bottom: 10px !important;
        }
      }
    }

    &__docs {
      margin-top: 10px;
      flex-direction: column;
      align-items: center;

      &-item {
        margin-bottom: 5px;

        &_copyright {
          order: 1;
        }
      }
    }
  }
}