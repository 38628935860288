.main-page {
    padding-top: 16px;
}

.first-screen {
    position: relative;
    z-index: 1;
    background-color: rgba(14, 26, 73, .7);
    padding-top: 138px;
    padding-bottom: 140px
}

.first-screen .text {
    color: #fff;
}

.first-screen .content {
    position: relative;
    max-width: 669px;
}

.first-screen .video {
    background-color: rgba(14, 26, 73, .7);
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-filter: brightness(.8);
    filter: brightness(.8);
}

.full-height {
    min-height: -webkit-calc(100vh - 32px);
    min-height: calc(100vh - 32px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

span.arrow, button.arrow {
    padding-right: 32px;
}

span.arrow:before, button.arrow:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    background: url(../../svg/arrow_link.svg) no-repeat center 75%;
}

.line_hover {
    position: relative;
    display: inline-table;
    text-decoration: none!important;
    cursor: pointer;
}

.line_hover.arrow {
    color: #f67c1f;
}

.line_hover.arrow:after {
    bottom: -7px;
    right: 20px!important;
    content: "";
    position: absolute;
    height: 1px;
    width: 40px;
    -webkit-transform: scale(1, 1)!important;
    -ms-transform: scale(1, 1)!important;
    transform: scale(1, 1)!important;
    background: #f67c1f;
    -webkit-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -o-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -webkit-animation: none!important;
    animation: none!important;
}

.line_hover:after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background: #f67c1f;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -o-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -webkit-animation: line_hover_out .2s cubic-bezier(.785, .135, .15, .86) both;
    animation: line_hover_out .2s cubic-bezier(.785, .135, .15, .86) both;
}

.line_hover.arrow:hover::after {
    width: -webkit-calc(100% - 32px);
    width: calc(100% - 32px);
}

.video {
    display: block;
    visibility: visible;
    opacity: 1;
}

.video video {
    opacity: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.first-screen .content {
    position: relative;
    max-width: 720px;
}

.cd-headline.type .cd-words-wrapper {
    vertical-align: top;
    overflow: hidden;
}

.cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
    -webkit-transition-timing-function: cubic-bezier(.25,.46,.45,.94);
    -o-transition-timing-function: cubic-bezier(.25,.46,.45,.94);
    transition-timing-function: cubic-bezier(.25,.46,.45,.94);
}

[data-aos][data-aos][data-aos-duration='500'], body[data-aos-duration='500'] [data-aos] {
    -webkit-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;
}

.aos.fadeDone {
    opacity: 1;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    -webkit-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-timing-function: cubic-bezier(.25, .46, .45, .94);
    -o-transition-timing-function: cubic-bezier(.25, .46, .45, .94);
    transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    -webkit-transition-property: opacity,-webkit-transform;
    transition-property: opacity,-webkit-transform;
    -o-transition-property: opacity,transform;
    transition-property: opacity,transform;
    transition-property: opacity,transform,-webkit-transform;
}

.careesBlock .container>*, .pageNumber, .projectSlider, .scrollDown, .titleRow>* {
    opacity: 0;
}

.scrollDown {
    width: 119px;
    height: 107px;
    position: absolute;
    right: 56px;
    bottom: 47px;
    cursor: pointer;
}

.scrollDown:after {
    content: '';
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 1px;
    height: 60px;
    background: #f67c1f;
    top: 100%;
    margin-top: 20px;
    position: absolute;
}

[data-aos=fade-up] {
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
}

.scrollDown .text {
    -webkit-animation: 10s animRotate linear infinite;
    animation: 10s animRotate linear infinite;
}

.scrollDown .text {
    -webkit-animation: 10s animRotate linear infinite;
    animation: 10s animRotate linear infinite;
}

@-webkit-keyframes animRotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes animRotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.scrollDown .mouse circle {
    -webkit-animation: 2s bounceMouse ease-in infinite;
    animation: 2s bounceMouse ease-in infinite;
}

@-webkit-keyframes bounceMouse {
    10% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    60% {
        opacity: 1
    }
    80% {
        -webkit-transform: translateY(7px);
        transform: translateY(7px);
        opacity: 0
    }
    80.01% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    90% {
        opacity: 1
    }
}

@keyframes bounceMouse {
    10% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    60% {
        opacity: 1
    }
    80% {
        -webkit-transform: translateY(7px);
        transform: translateY(7px);
        opacity: 0
    }
    80.01% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    90% {
        opacity: 1
    }
}

.firstScreen .text {
    color: #fff;
}

.scrollDown svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}



@media screen and (max-width: 991px) {
    .wrapper.main-page {
        padding-top: 8px;
    }
    .wrapper {
        padding: 8px;
    }
}

@media screen and (max-width: 840px) {
    .full-height {
        min-height: initial;
    }
    .first-screen {
        padding-top: 138px;
        padding-bottom: 140px;
    }
}

@media screen and (max-width: 767px) {
    .wrapper {
        margin: 0 4px;
    }
}

@media screen and (max-width:480px) and (max-height:670px) {
    .first-screen {
        min-height: -webkit-calc(100vh - 80px);
        min-height: calc(100vh - 80px);
        padding-top: 60px;
        padding-bottom: 100px
    }
}