header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    right: 0;
    top: 16px;
    padding: 8px 48px 8px;
    border-left: 16px solid #fff;
    border-right: 16px solid #fff;
    z-index: 99991;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    background-color: var(--indigo);
    color: white;
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    /*background-image: linear-gradient(to top, #16224e, var(--indigo));*/
}

header:before {
    content: '';
    position: absolute;
    left: -16px;
    right: -16px;
    top: -41px;
    height: 45px;
    background: #fff;
}

.header-content {
    display: flex;
    justify-content: space-between;
}

.header__nav {
    display: flex;
    align-items: center;
}

.main-logo {
    width: 370px;
    height: 45px;
    background: url("../../svg/pbfs_Full_Name_Logonew.svg") no-repeat center center;
}

/*Language block*/
.lang-block {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
}

.lang-block span {
    flex: 1 1 0;
    font-family: Montserrat, serif;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 15px;
    line-height: 18px;
}

.lang-block svg {
    margin: 0 16px;
}

/*Navigation*/
nav > span {
    margin-right: 56px;
    font-family: Montserrat, serif;
    font-size: 15px;
}

nav span:last-child {
    margin-right: 0
}

nav span {
    font-family: Montserrat, serif;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none!important;
}

nav span.active {
    color: #f67c1f
}

nav span:hover {
    color: #f67c1f
}

header nav a {
    color: #fff;
    text-decoration: none!important;
}

nav .line_hover:after {
    pointer-events: none;
    height: 6px;
    bottom: -15px;
    left: -2px;
    right: -2px;
    width: auto;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
}

/*Mob navigation*/
.mobile_row .wrap,
.popupContent,
.popupWrap {
    -webkit-overflow-scrolling: touch;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.mobile_row {
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: 0;
    z-index: 50;
    overflow: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    border: 8px solid transparent;
    padding-top: 70px;
    /*border-color: #fff;*/
    height: 99vh;
}

.dialog-nav-mob-enter {
    height: 30vh;
    transition: height 200ms;
}
.dialog-nav-mob-enter-active {
    height: 99vh;
}
.dialog-nav-mob-exit {
    height: 98vh;
    transition: height 0ms;
}
.dialog-nav-mob-exit-active {
    height: 0;
}

.mobile_row:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: rgba(22, 34, 78, .8);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px)
}

.mobile_row .close {
    position: absolute;
    top: 24px;
    right: 24px;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.mobile_row .close rect {
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.mobile_row .close:hover {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.mobile_row .close:hover rect {
    fill: #f67c1f
}

.mobile_row .wrap {
    padding: 20px 20px 70px;
    display: block;
    width: 100%;
    height: -webkit-calc(100% - 8px);
    height: calc(100% - 8px);
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.mobile_row .lang-block {
    display: block;
    text-align: center;
    margin-top: 75px;
}

.lang-block svg {
    margin: 0 12px;
}

.lang-block span {
    color: #fff;
    font-family: Montserrat, serif;
    text-decoration: none;
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;
}

.mobile_row nav {
    display: block;
}

.mobile_row nav li {
    list-style-type: none;
    margin-right: 0;
    margin-bottom: 17px;
    text-align: center;
    -webkit-box-ordinal-group: 91;
    -webkit-order: 90;
    -ms-flex-order: 90;
    order: 90;
}

.dialog-line-enter {
    -webkit-transform: scale(1.05) translate(0, -24px);
    -ms-transform: scale(1.05) translate(0, -24px);
    transform: scale(1.05) translate(0, -24px);
    transition: all .5s;
}
.dialog-line-enter-active {
    -webkit-transform: scale(1) translate(0, 0);
    -ms-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
}

.mobile_row nav ol {
    padding-left: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.mobile_row nav ol li:first-child {
    display: none;
}

.mobile_row nav span {
    font-size: 24px;
    line-height: 24px;
    color: #fff;
}

.mobile_row nav span:after {
    display: none;
}

.mobile_row nav span.active {
    color: #f67c1f;
}

.dialog-lang-block-enter {
    -webkit-transform: scale(1.05) translate(0, 24px);
    -ms-transform: scale(1.05) translate(0, 24px);
    transform: scale(1.05) translate(0, 24px);
    transition: all .5s;
}
.dialog-lang-block-enter-active {
    -webkit-transform: scale(1) translate(0, 0);
    -ms-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
}

/*BURGER*/
.menu-btn {
    overflow: hidden;
    width: 32px;
    height: 18px;
    cursor: pointer;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
    position: absolute;
    margin: auto;
    display: none;
    bottom: 0;
    top: 7px;
    right: 20px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.menu-btn.no_access {
    opacity: .5
}

.menu-btn:after,
.menu-btn:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    height: 2px;
    width: 22px;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    opacity: 0
}

.menu-btn.active_line2 .line {
    opacity: 0;
    visibility: hidden
}

.menu-btn.active_line2:before {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-box-shadow: none;
    box-shadow: none
}

.menu-btn.active_line2:after {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.menu-btn .line {
    background-color: #fff;
    height: 2px;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    width: 100%;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    -webkit-animation: slide-bar-long 5s .3s infinite;
    animation: slide-bar-long 5s .3s infinite;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-border-radius: 5%;
    border-radius: 5%
}

.menu-btn .line:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    left: -webkit-calc(100% + 8px);
    left: calc(100% + 8px);
    height: 100%;
    width: 100%;
    -webkit-border-radius: 5%;
    border-radius: 5%
}

.menu-btn .line:nth-child(1),
.menu-btn .line:nth-child(3) {
    display: block;
    margin-right: 0;
    content: "";
    background-color: #fff;
    right: 0;
    position: absolute
}

.menu-btn .line:nth-child(1):before,
.menu-btn .line:nth-child(3):before {
    background-color: #fff;
    left: -webkit-calc(100% + 8px);
    left: calc(100% + 8px)
}

.menu-btn .line:nth-child(1) {
    top: 0;
    bottom: auto;
    -webkit-animation: slide-bar 5s infinite;
    animation: slide-bar 5s infinite;
    width: 100%
}

.menu-btn .line:nth-child(3) {
    top: auto;
    bottom: 0;
    -webkit-animation: slide-bar 5s .58s infinite;
    animation: slide-bar 5s .58s infinite
}

.menu-btn.active .line {
    opacity: 0;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}

@-webkit-keyframes slide-bar {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    50% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        -webkit-transform: translate3d(-webkit-calc(-100% - 8px), 0, 0);
        transform: translate3d(calc(-100% - 8px), 0, 0)
    }
}

@keyframes slide-bar {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    50% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        -webkit-transform: translate3d(-webkit-calc(-100% - 8px), 0, 0);
        transform: translate3d(calc(-100% - 8px), 0, 0)
    }
}

@-webkit-keyframes slide-bar-long {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    50% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        -webkit-transform: translate3d(-webkit-calc(-100% - 8px), 0, 0);
        transform: translate3d(calc(-100% - 8px), 0, 0)
    }
}

@keyframes slide-bar-long {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    50% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        -webkit-transform: translate3d(-webkit-calc(-100% - 8px), 0, 0);
        transform: translate3d(calc(-100% - 8px), 0, 0)
    }
}

@media screen and (max-width:1280px) {
    nav span {
        margin-right: 40px
    }
}

@media screen and (max-width:1180px) {
    nav span {
        margin-right: 30px
    }
}

@media screen and (max-width:991px) and (max-height:680px) {
    .mobile_row nav span {
        font-size: 38px;
        line-height: 40px
    }
    .mobile_row nav li {
        margin-bottom: 11px
    }
    .mobile_row {
        padding-top: 50px
    }
}

@media screen and (max-width:991px) and (max-height:490px) {
    .mobile_row .lang-block {
        margin-top: 20px
    }
    .mobile_row nav span {
        font-size: 32px;
        line-height: 30px
    }
}

@media screen and (max-width: 991px) {
    header > nav {
        display: none !important;
    }
    .menu-btn {
        display: block;
    }
    .header-content > .lang-block {
        display: none;
    }
    .main-logo svg {
        width: 300px;
    }
    header {
        top: 8px;
        padding-left: 21px;
        border-left-width: 12px;
        border-right-width: 12px;
    }
}

@media screen and (max-width: 768px) {
    .main-logo svg {
        width: 250px;
    }
}

@media screen and (max-width: 414px) {
    .main-logo svg {
        width: 200px;
    }
}

@media screen and (max-width: 365px) {
    .header-content svg {
        width: 250px;
    }
}
