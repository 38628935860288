.start-ups {
  padding-top: 50px;
  margin-bottom: 0;

  &__container {
    padding-bottom: 0;
  }
  &__criteria {
    margin-top: 0;
    flex-wrap: nowrap;
  }
}

@media (max-width: 750px) {
  .start-ups {
    &__criteria {
      flex-wrap: wrap;

      & p {
        max-width: 100% !important;
      }
    }
  }
}