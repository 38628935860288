.projectSingleSliderItem {
    height: 266px;
    width: 27vw;
}

.articleNavigationItem .line_hover:after {
    background: #fff;
}

@media screen and (min-width: 1280px) {
    .articleNavigationItem.team svg {
        height: 85.1px;
        max-width: none;
        width: auto;
    }
    .articleNavigationItem.projects svg {
        height: 82px;
        max-width: none!important;
        width: auto!important;
    }

    .articleNavigationItem.team .fr svg {
        height: 102.34px;
        max-width: none;
        width: auto;
        top: -10px;
        position: relative;
    }
}

@media screen and (max-width: 991px) {
    .projectSliderItem {
        width: -webkit-calc(100vw * .4);
        width: calc(100vw * .4);
    }
}

@media screen and (max-width: 840px) {
    .projectSliderItem {
        width: -webkit-calc(100vw * .75);
        width: calc(100vw * .75);
    }
}

@media screen and (max-width: 640px) {
    .projectSingleSliderItem {
        height: 38.15vw;
        min-height: 143px;
    }
}

@media screen and (max-width: 540px) {
    .projectSliderItem {
        width: -webkit-calc(100vw - 23px);
        width: calc(100vw - 23px);
    }
}




