:root {
    --orange: #FC4C03;
    --indigo: #001E60;
}

@font-face {
    font-family: Constantia;
    src: url(./fonts/Constantia/3AA93D_0_0.eot);
    src: url(./fonts/Constantia/3AA93D_0_0.eot?#iefix) format('embedded-opentype'), url(./fonts/Constantia/3AA93D_0_0.woff2) format('woff2'), url(./fonts/Constantia/3AA93D_0_0.woff) format('woff'), url(./fonts/Constantia/3AA93D_0_0.ttf) format('truetype');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Constantia;
    src: url(./fonts/Constantia/3AA93D_1_0.eot);
    src: url(./fonts/Constantia/3AA93D_1_0.eot?#iefix) format('embedded-opentype'), url(./fonts/Constantia/3AA93D_1_0.woff2) format('woff2'), url(./fonts/Constantia/3AA93D_1_0.woff) format('woff'), url(./fonts/Constantia/3AA93D_1_0.ttf) format('truetype');
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Bold.ttf);
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: Roboto Slab;
    src: url(./fonts/RobotoSlab/RobotoSlab-Regular.ttf);
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Roboto Slab;
    src: url(./fonts/RobotoSlab/RobotoSlab-Bold.ttf);
    font-weight: 700;
    font-style: normal
}

/*@font-face {*/
/*    font-family: Montserrat;*/
/*    src: url(./fonts/Montserrat/Montserrat-ExtraBold.ttf);*/
/*    font-weight: 400;*/
/*    font-style: normal*/
/*}*/

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format('truetype')
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype')
}

@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Overpass Bold'), local('Overpass-Bold'), url(https://fonts.gstatic.com/s/overpass/v4/qFdA35WCmI96Ajtm81keds7N4h8.ttf) format('truetype')
}

.titleRow .title-SVG span {
    position: absolute;
    top: 50%;
    right: -51px;
    transform: translateY(-50%);
    z-index: 9999;
}

.align-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.active-text {
    color: #f67c1f !important;
}

.color-text {
    color: #f67c1f;
}

.white-text .text, .white-text h1, .white-text h2, .white-text h3, .white-text h4, .white-text h5, .white-text h6 {
    color: #fff;
}

@media screen and (max-width: 991px) {
    .h1, h1 {
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 40px;
    }

    .h2, h2 {
        font-size: 36px;
        line-height: 120%;
        margin-bottom: 19px;
    }

    .first-screen .container, footer .container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-page .container {
        padding-left: 0;
        padding-right: 0;
    }

    .first-screen .container, footer .container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .titleRow {
        margin-bottom: 48px
    }
}

body,
html {
    margin: 0;
    padding: 0;
    min-width: 320px;
    font-family: Montserrat, serif;
    font-weight: 400;
    font-size: 18px;
    color: #16224e;
    /*overflow-x: hidden;*/
    /*overflow-y: auto;*/
    -webkit-text-size-adjust: 100%
}

body {
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: geometricPrecision
}

img {
    max-width: 100%
}

svg use {
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

button,
input,
textarea {
    -webkit-appearance: none
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

input,
textarea {
    color: #252525;
    font-family: Montserrat, serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    height: 55px;
    background-color: #fff;
    border: 2px solid #16224e;
    outline: 0;
    padding: 5px 18px
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #252525
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #252525
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #252525
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #252525
}

textarea {
    padding-top: 14px;
    padding-bottom: 14px
}

input.error,
label.error,
textarea.error {
    border-color: #c11212!important
}

label.error input {
    border-color: #c11212!important
}

label.checkbox {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -.73px;
    line-height: 25px;
    color: #fff;
    display: table;
    position: relative;
    padding-left: 27px;
    cursor: pointer;
    margin-bottom: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0!important
}

label.checkbox:last-of-type {
    margin-bottom: 0
}

label.checkbox .check {
    position: absolute;
    left: 0;
    background: 0 0;
    height: 20px;
    width: 20px;
    border: 1px solid #000;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    top: 3px
}

label.checkbox span {
    font-size: 16px;
    line-height: 24px;
    color: #000
}

label.checkbox span span {
    opacity: .5
}

label.checkbox input {
    display: none
}

label.checkbox input:checked+.check:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 12px;
    width: 12px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    background-color: #000
}

label.checkbox.error .check {
    border-color: RED
}

.with_line,
label.with_line {
    display: inline-block;
    margin-bottom: 12px;
    cursor: text;
    position: relative
}

.with_line:after,
label.with_line:after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background: #f67c1f;
    -webkit-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -o-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    transition: all .6s cubic-bezier(.785, .135, .15, .86)
}

.with_line.hover:after,
label.with_line.hover:after {
    width: 100%
}

.with_line .fs-dropdown:before,
label.with_line .fs-dropdown:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background: #4a93bb;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    z-index: 7
}

.with_line .fs-dropdown-selected,
label.with_line .fs-dropdown-selected {
    -webkit-border-radius: 0;
    border-radius: 0;
    padding-left: 0
}

.with_line .fs-dropdown-open:before,
label.with_line .fs-dropdown-open:before {
    width: 100%
}

.with_line .fs-dropdown-item,
label.with_line .fs-dropdown-item {
    min-height: 37px
}

.with_line .fs-dropdown-options,
label.with_line .fs-dropdown-options {
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px)
}

.with_line .fs-dropdown-selected,
.with_line input,
.with_line textarea,
label.with_line .fs-dropdown-selected,
label.with_line input,
label.with_line textarea {
    border: 2px solid transparent;
    border-bottom-color: #16224e;
    -webkit-border-radius: 0;
    border-radius: 0;
    font-size: 18px;
    width: 100%;
    color: #16224e;
    height: 52px;
    outline: 0;
    background: 0 0;
    padding: 0;
    font-weight: 400
}

.with_line .fs-dropdown-selected::-webkit-input-placeholder,
.with_line input::-webkit-input-placeholder,
.with_line textarea::-webkit-input-placeholder,
label.with_line .fs-dropdown-selected::-webkit-input-placeholder,
label.with_line input::-webkit-input-placeholder,
label.with_line textarea::-webkit-input-placeholder {
    color: #16224e
}

.with_line .fs-dropdown-selected::-moz-placeholder,
.with_line input::-moz-placeholder,
.with_line textarea::-moz-placeholder,
label.with_line .fs-dropdown-selected::-moz-placeholder,
label.with_line input::-moz-placeholder,
label.with_line textarea::-moz-placeholder {
    color: #16224e
}

.with_line .fs-dropdown-selected:-moz-placeholder,
.with_line input:-moz-placeholder,
.with_line textarea:-moz-placeholder,
label.with_line .fs-dropdown-selected:-moz-placeholder,
label.with_line input:-moz-placeholder,
label.with_line textarea:-moz-placeholder {
    color: #16224e
}

.with_line .fs-dropdown-selected:-ms-input-placeholder,
.with_line input:-ms-input-placeholder,
.with_line textarea:-ms-input-placeholder,
label.with_line .fs-dropdown-selected:-ms-input-placeholder,
label.with_line input:-ms-input-placeholder,
label.with_line textarea:-ms-input-placeholder {
    color: #16224e
}

.with_line textarea,
label.with_line textarea {
    resize: none;
    display: block;
    height: 85px;
    padding: 15px 0
}

.contactForm .with_line textarea {
    margin-top:25px;
}

.terms-alert {
    display: block;
    border: 2px solid #f7e700;
    margin: 2em 0.5em 1em;
    padding: 0.2em 1em;
}

.with_line.error input,
label.with_line.error input {
    border-bottom-color: #c11212!important
}

.with_line.error:after,
label.with_line.error:after {
    background: #c11212!important
}

.with_line.textarea .hover_text,
label.with_line.textarea .hover_text {
    max-height: 58px
}

.with_line .hover_text,
label.with_line .hover_text {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Montserrat, serif;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.with_line.hover .hover_text,
label.with_line.hover .hover_text {
    -webkit-transform: translate(2px, -15px);
    -ms-transform: translate(2px, -15px);
    transform: translate(2px, -15px);
    font-size: 12px;
    font-weight: 700
}

.with_line:hover:after,
label.with_line:hover:after {
    width: 100%
}

.with_line.error input,
label.with_line.error input {
    border-top-color: transparent!important;
    border-left-color: transparent!important;
    border-right-color: transparent!important;
    border-bottom-color: #16224e!important
}

.with_line.error:after,
label.with_line.error:after {
    background: #c11212
}

.with_line.error:after,
label.with_line.error:after {
    background: #c11212;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    width: 100%
}

.with_line .error_text,
label.with_line .error_text {
    position: absolute;
    bottom: 3px;
    left: 0;
    font-weight: 700;
    font-size: 12px;
    padding-top: 3px;
    color: #c11212;
    line-height: initial
}

button {
    outline: 0
}

.clear {
    clear: both;
    float: none
}

.clear:after {
    content: "";
    display: table;
    clear: both;
    float: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: initial;
    margin-top: 0;
    color: #16224e;
    font-family: Montserrat, serif;
    letter-spacing: 0
}

h1 b,
h1 strong,
h2 b,
h2 strong,
h3 b,
h3 strong,
h4 b,
h4 strong,
h5 b,
h5 strong,
h6 b,
h6 strong {
    font-weight: inherit;
    color: #f67c1f
}

.h1,
h1 {
    font-size: 41px;
    margin-bottom: 34px;
    line-height: 140%
}

.h1.small,
h1.small {
    font-size: 24px;
    letter-spacing: .6px;
    line-height: 28px;
}

.h2,
h2 {
    line-height: 110%;
    font-size: 35px;
}

.h2.small,
h2.small {
    font-size: 30px
}

.h3,
h3 {
    font-size: 36px
}

.h4,
h4 {
    font-size: 32px;
    margin-bottom: 18px
}

.h5,
h5 {
    font-size: 28px
}

.h6,
h6 {
    font-size: 24px
}

.alignTextLeft {
    text-align: left!important
}

.alignTextCenter {
    text-align: center!important
}

.alignTextRight {
    display: block;
    text-align: right!important
}

.justifyCenter {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.alignCenter {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.alignStart {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.alignEnd {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.flexColumn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

a {
    -webkit-transition: color .2s ease;
    -o-transition: color .2s ease;
    transition: color .2s ease;
    text-decoration: none;
    color: #f67c1f
}

p {
    margin-top: 0
}

li,
ul {
    margin: 0;
    padding: 0
}

.clear {
    clear: both
}

.container {
    max-width: 1276px;
    margin: auto;
    position: relative;
    width: 100%;
    padding: 75px 60px 64px;
}

.container.no_padding_top {
    padding-top: 0
}

.container.no_padding_bottom {
    padding-bottom: 0
}

.container_without_padding {
    padding-top: 0;
    padding-bottom: 0
}

.container:after {
    content: "";
    display: table;
    clear: both
}

.wrapper {
    overflow: hidden;
    margin: 0 16px;
    padding-top: 145px
}
@media screen and (max-width:767px) {
    .wrapper {
        margin: 0 4px;
    }
}
.wrapper.mainPage {
    padding-top: 16px
}

.wrapper>div {
    position: relative
}

.wrapper>div:after {
    content: "";
    display: table;
    clear: both
}

.mainBtn {
    -webkit-box-shadow: 0 18px 21px rgba(255, 15, 33, .16);
    box-shadow: 0 18px 21px rgba(255, 15, 33, .16);
    height: 46px;
    background-color: #ff0f21;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    min-width: 200px;
    color: #fff;
    font-family: "Open Sans", serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border: 0;
    outline: 0;
    cursor: pointer;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    text-decoration: none!important;
    min-height: 46px;
    padding: 4px 20px;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /*display: -webkit-inline-box;*/
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.mainBtn.no_bg {
    background: 0 0;
    color: #242424;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #000;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}

.mainBtn.no_bg:hover {
    background: #000;
    color: #fff
}

.mainBtn:hover {
    background: #f67c1f
}

.owl-carousel {
    -ms-touch-action: none;
    touch-action: none
}

.owl-dot {
    display: inline-block;
    margin-right: 20px
}

.owl-dot:last-child {
    margin-right: 0
}

.owl-dot span {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: block;
    width: 11px;
    height: 11px;
    border: 1px solid #282828;
    background-color: #fff;
    cursor: pointer
}

.owl-dot.active span {
    background-color: #161616
}

.owl-next,
.owl-prev {
    width: 17px;
    height: 32px;
    float: left;
    /*background: red url(../../img/vector-smart-object-copy.png) no-repeat center center;*/
    background-size: contain;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-prev {
    margin-right: 46px
}

.owl-next {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.owl-dot {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.seo {
    font-size: 16px;
    line-height: 24px;
    font-family: "Open Sans", serif;
    color: #505050
}

.seo.container {
    padding-top: 53px;
    padding-bottom: 10px
}

.seo p {
    margin-bottom: 24px
}

.seo ol,
.seo ul {
    margin-bottom: 22px;
    padding-left: 0;
    list-style: none
}

.seo ol li,
.seo ul li {
    position: relative;
    padding-left: 26px;
    margin-bottom: 10px
}

.seo ol li:last-child,
.seo ul li:last-child {
    margin-bottom: 0
}

.seo ol li:before,
.seo ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
    width: 10px;
    height: 10px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: #ff0f21
}

.close-btn {
    display: none;
    width: 30px;
    height: 21px;
    position: absolute;
    right: 20px;
    top: 22px;
    bottom: 0;
    margin: auto
}

.close-btn:after,
.close-btn:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 3px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.close-btn:before {
    -webkit-box-shadow: 0 9px 0 #fff;
    box-shadow: 0 9px 0 #fff;
    margin-bottom: 15px
}

.close-btn.on:before {
    -webkit-box-shadow: 0 0 0 #fff;
    box-shadow: 0 0 0 #fff;
    -webkit-transform: translateY(10px) rotate(45deg);
    -ms-transform: translateY(10px) rotate(45deg);
    transform: translateY(10px) rotate(45deg)
}

.close-btn.on:after {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    -ms-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg)
}

.menu-btn {
    overflow: hidden;
    width: 32px;
    height: 18px;
    cursor: pointer;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
    position: absolute;
    margin: auto;
    display: none;
    bottom: 0;
    top: 7px;
    right: 20px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.menu-btn.no_access {
    opacity: .5
}

.menu-btn:after,
.menu-btn:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    height: 2px;
    width: 22px;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    opacity: 0
}

.menu-btn.active_line2 .line {
    opacity: 0;
    visibility: hidden
}

.menu-btn.active_line2:before {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-box-shadow: none;
    box-shadow: none
}

.menu-btn.active_line2:after {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.menu-btn .line {
    background-color: #fff;
    height: 2px;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    width: 100%;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    -webkit-animation: slide-bar-long 5s .3s infinite;
    animation: slide-bar-long 5s .3s infinite;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-border-radius: 5%;
    border-radius: 5%
}

.menu-btn .line:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    left: -webkit-calc(100% + 8px);
    left: calc(100% + 8px);
    height: 100%;
    width: 100%;
    -webkit-border-radius: 5%;
    border-radius: 5%
}

.menu-btn .line:nth-child(1),
.menu-btn .line:nth-child(3) {
    display: block;
    margin-right: 0;
    content: "";
    background-color: #fff;
    right: 0;
    position: absolute
}

.menu-btn .line:nth-child(1):before,
.menu-btn .line:nth-child(3):before {
    background-color: #fff;
    left: -webkit-calc(100% + 8px);
    left: calc(100% + 8px)
}

.menu-btn .line:nth-child(1) {
    top: 0;
    bottom: auto;
    -webkit-animation: slide-bar 5s infinite;
    animation: slide-bar 5s infinite;
    width: 100%
}

.menu-btn .line:nth-child(3) {
    top: auto;
    bottom: 0;
    -webkit-animation: slide-bar 5s .58s infinite;
    animation: slide-bar 5s .58s infinite
}

.menu-btn.active .line {
    opacity: 0;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}

@-webkit-keyframes slide-bar {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    50% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        -webkit-transform: translate3d(-webkit-calc(-100% - 8px), 0, 0);
        transform: translate3d(calc(-100% - 8px), 0, 0)
    }
}

@keyframes slide-bar {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    50% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        -webkit-transform: translate3d(-webkit-calc(-100% - 8px), 0, 0);
        transform: translate3d(calc(-100% - 8px), 0, 0)
    }
}

@-webkit-keyframes slide-bar-long {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    50% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        -webkit-transform: translate3d(-webkit-calc(-100% - 8px), 0, 0);
        transform: translate3d(calc(-100% - 8px), 0, 0)
    }
}

@keyframes slide-bar-long {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    50% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        -webkit-transform: translate3d(-webkit-calc(-100% - 8px), 0, 0);
        transform: translate3d(calc(-100% - 8px), 0, 0)
    }
}

.fs-dropdown {
    width: 100%;
    font-size: 14px;
    outline: 0
}

.fs-dropdown-selected {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    height: 55px;
    padding-left: 18px;
    outline: 0;
    border: 0;
    padding-right: 35px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #252525;
    font-family: "Open Sans", serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px
}

.fs-dropdown-selected:after {
    content: "";
    position: absolute;
    margin: auto;
    width: 22px;
    height: 12px;
    top: 4px;
    bottom: 0;
    right: 15px;
    /*background: url(../../img/arrow.png) no-repeat center center;*/
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0)
}

.fs-dropdown-open .fs-dropdown-selected:after {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.fs-dropdown-open .fs-dropdown-selected {
    -webkit-border-radius: 6px 6px 0 0!important;
    border-radius: 6px 6px 0 0!important
}

.fs-dropdown-options {
    -webkit-box-shadow: 0 23px 38px rgba(0, 0, 0, .13);
    box-shadow: 0 23px 38px rgba(0, 0, 0, .13);
    border: 1px solid #e3e3e3;
    border-top: 0;
    top: -webkit-calc(100% + 3px);
    top: 100%;
    -webkit-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px
}

.fs-dropdown-options button {
    min-height: 45px;
    padding-left: 22px;
    padding-right: 22px;
    color: #5a676e;
    outline: 0;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.fs-dropdown-options button:first-of-type {
    border-top: 0
}

.fs-dropdown-options button:hover {
    background: #ceffb3
}

.fs-dropdown-bottom .fs-dropdown-options {
    bottom: 100%;
    -webkit-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
    border-bottom: 0
}

.fs-dropdown-bottom .fs-dropdown-selected {
    -webkit-border-radius: 0 0 6px 6px!important;
    border-radius: 0 0 6px 6px!important
}

.fs-dropdown-item_selected {
    background: 0 0
}

.fs-dropdown-item {
    min-height: 55px;
    height: 55px;
    color: #252525!important;
    font-family: "Open Sans", serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px
}

.tabs {
    padding-top: 49px;
    margin-bottom: 46px
}

.tabs .main_tabs div {
    color: #252525;
    font-family: "Open Sans - Semibold", "Open Sans", serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: underline;
    display: inline-block;
    margin-right: 47px;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.tabs .main_tabs div:last-child {
    margin-right: 0
}

.tabs .main_tabs div:hover {
    color: #f67c1f
}

.tabs .main_tabs div.active {
    color: #252525!important;
    text-decoration: none
}

.tabs .tabs_content {
    margin-top: 36px
}

.tabs .tabs_content .before_slider:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -50vw;
    left: -50vw;
    background-color: rgba(237, 237, 237, .5);
    display: block;
    z-index: -1
}

.tabs .tabs_content .item:nth-child(1) .container {
    padding-top: 25px
}

.tabs .tabs_content .item:nth-child(2n+2) {
    background-color: rgba(237, 237, 237, .5)
}

.tabs .tabs_content .item .container {
    padding-top: 14px;
    padding-bottom: 0
}

.tabs .tabs_content .item .before_slider {
    padding-bottom: 19px
}

.tabs .tabs_content .item .offer {
    margin-bottom: 13px
}

.animation0s {
    -webkit-animation: none!important;
    animation: none!important;
    -webkit-animation-delay: 0s!important;
    animation-delay: 0s!important
}

.animation0s * {
    -webkit-animation: none!important;
    animation: none!important;
    -webkit-animation-delay: 0s!important;
    animation-delay: 0s!important
}

.transition0s {
    -webkit-transition: 0s!important;
    -o-transition: 0s!important;
    transition: 0s!important;
    -webkit-transition-delay: 0s!important;
    -o-transition-delay: 0s!important;
    transition-delay: 0s!important
}

.transition0s * {
    -webkit-transition: 0s!important;
    -o-transition: 0s!important;
    transition: 0s!important;
    -webkit-transition-delay: 0s!important;
    -o-transition-delay: 0s!important;
    transition-delay: 0s!important
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 -15px;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap
}

.row.nowrap {
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.row .w100 {
    width: 100%;
    margin-left: 0;
    margin-right: 0
}

.row .w50 {
    width: -webkit-calc(50% - 16px);
    width: calc(50% - 16px)
}

.row .w33 {
    width: -webkit-calc(33.333% - 16px);
    width: calc(33.333% - 16px)
}

.row .w25 {
    width: -webkit-calc(25% - 16px);
    width: calc(25% - 16px)
}

.row .w25,
.row .w33,
.row .w50 {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px
}

.nowrap {
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.margin0 {
    margin: 0
}

.custom_dropdown {
    display: inline-block;
    position: relative
}

.custom_dropdown>a {
    position: relative
}

.custom_dropdown .top {
    position: relative;
    z-index: 1;
    padding-right: 19px;
    cursor: pointer;
    -webkit-transition: color .2s;
    -o-transition: color .2s;
    transition: color .2s;
    padding-left: 20px!important
}

.custom_dropdown .top button {
    background: 0 0!important
}

.custom_dropdown .top .arrow {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 2px;
    right: 0;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background: url(./svg/arrow_simple_orange.svg) no-repeat center center
}

.custom_dropdown .hidden .wrap {
    display: inline-table;
    margin-left: auto;
    margin-right: auto;
    text-align: left
}

.custom_dropdown ul {
    display: none;
    position: absolute;
    overflow: hidden;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 20;
    text-align: center;
    padding-left: 0;
    list-style: none;
    width: 100%;
    left: 0;
    padding-bottom: 32px
}

.custom_dropdown ul li {
    margin: 0;
    width: 100%;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    opacity: 1;
    font-size: 16px;
    margin-bottom: 16px;
    padding-left: 20px;
    cursor: pointer;
}

.custom_dropdown ul li:hover {
    color: #f67c1f;
}

.custom_dropdown ul li:last-child {
    margin-bottom: 0
}

.custom_dropdown ul li a {
    font-size: inherit;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    position: relative;
    color: #16224e;
    text-decoration: none
}

.custom_dropdown ul li a span {
    display: table-cell;
    vertical-align: middle
}

.custom_dropdown ul li a:hover {
    color: #f67c1f
}

.custom_dropdown ul li:last-child {
    margin-bottom: 0;
    border: 0
}

.custom_dropdown ul.slide_top {
    top: auto;
    bottom: -webkit-calc(100% + 3px);
    bottom: calc(100% + 3px)
}

.custom_dropdown.open .arrow {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.custom_dropdown.open .top:before {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1)
}

.helloBlock {
    text-align: center;
    margin-bottom: 25px
}

.helloBlock img {
    margin: 0 auto 25px
}

.mobile_row .wrap,
.popupContent,
.popupWrap {
    -webkit-overflow-scrolling: touch;
    -ms-touch-action: pan-y;
    touch-action: pan-y
}

.line_hover {
    position: relative;
    display: inline-table;
    text-decoration: none!important
}

.line_hover:after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background: #f67c1f;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -o-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -webkit-animation: line_hover_out .2s cubic-bezier(.785, .135, .15, .86) both;
    animation: line_hover_out .2s cubic-bezier(.785, .135, .15, .86) both
}

.line_hover.active:after,
.line_hover:hover:after {
    -webkit-animation: line_hover_in .2s cubic-bezier(.785, .135, .15, .86) both;
    animation: line_hover_in .2s cubic-bezier(.785, .135, .15, .86) both
}

.line_hover.tel:after {
    left: 1px;
    right: 3px
}

.line_hover.type2:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 40px;
    -webkit-transform: scale(1, 1)!important;
    -ms-transform: scale(1, 1)!important;
    transform: scale(1, 1)!important;
    background: #f67c1f;
    -webkit-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -o-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -webkit-animation: none!important;
    animation: none!important
}

.line_hover.type2:hover:after {
    width: 100%
}

.line_hover.pinkColor {
    color: #f67c1f!important
}

.line_hover.big:after {
    height: 4px;
    bottom: 0
}

.line_hover.arrow:after {
    right: 20px!important
}

.line_hover.arrow:after {
    bottom: -7px
}

.line_hover.arrow:hover::after {
    width: -webkit-calc(100% - 32px);
    width: calc(100% - 32px)
}

.line_hover.arrow.bigPadding:hover::after {
    width: -webkit-calc(100% - 40px);
    width: calc(100% - 40px)
}

a.arrow,
button.arrow {
    padding-right: 32px
}

a.arrow:before,
button.arrow:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    background: url(./svg/arrow_link.svg) no-repeat center 75%
}

a.arrow.bigPadding,
button.arrow.bigPadding {
    padding-right: 35px;
    background-position-y: 58%
}

@-webkit-keyframes line_hover_in {
    0% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scale(0, 1);
        transform: scale(0, 1)
    }
    100% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1)
    }
}

@keyframes line_hover_in {
    0% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scale(0, 1);
        transform: scale(0, 1)
    }
    100% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1)
    }
}

@-webkit-keyframes line_hover_out {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transform-origin: 100% 0;
        transform-origin: 100% 0
    }
    100% {
        -webkit-transform: scale(0, 1);
        transform: scale(0, 1);
        -webkit-transform-origin: 100% 0;
        transform-origin: 100% 0
    }
}

@keyframes line_hover_out {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transform-origin: 100% 0;
        transform-origin: 100% 0
    }
    100% {
        -webkit-transform: scale(0, 1);
        transform: scale(0, 1);
        -webkit-transform-origin: 100% 0;
        transform-origin: 100% 0
    }
}

b,
strong {
    font-weight: 500
}

ol {
    list-style: none;
    counter-reset: olCounter
}

ol li {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 8px
}

ol li:before {
    counter-increment: olCounter;
    content: counter(olCounter) ". ";
    color: #f67c1f;
    font-weight: 700
}

.text {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 165%;
    color: #16224e
}

.text li,
.text ol,
.text p,
.text table,
.text ul {
    font: inherit;
    color: inherit
}

.text a {
    color: #f67c1f
}

.text a.arrow {
    line-height: 22px
}

.text>:last-child {
    margin-bottom: 0
}

.text ul {
    list-style: none
}

.text ul li {
    position: relative;
    padding-left: 40px
}

.text ul li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    background: url(./svg/bullet.svg) no-repeat center center;
    background-size: contain;
    width: 20px;
    height: 20px
}

.text ol,
.text ul {
    max-width: 537px;
    margin-bottom: 72px
}

.text ol li,
.text ul li {
    margin-bottom: 24px;
    letter-spacing: .15px
}

.ul1 {
    max-width: 100% !important;
}

.ul1 li {
    margin-bottom: 12px !important;
}

.text ol li:last-child,
.text ul li:last-child {
    margin-bottom: 0
}

.text.lessWidth ol,
.text.lessWidth p,
.text.lessWidth table,
.text.lessWidth ul {
    max-width: 480px
}

.text.mediumWidth ol,
.text.mediumWidth p,
.text.mediumWidth table,
.text.mediumWidth ul {
    max-width: 768px
}

.lazy {
    opacity: 0;
    visibility: hidden
}

.fullHeight {
    min-height: -webkit-calc(100vh - 32px);
    min-height: calc(100vh - 32px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px
}

header {
    position: fixed;
    left: 0;
    right: 0;
    top: 16px;
    padding-top: 18px;
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 18px;
    border-left: 16px solid #fff;
    border-right: 16px solid #fff;
    z-index: 99991;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    background-color: #16224e;
}

header:before {
    content: '';
    position: absolute;
    left: -16px;
    right: -16px;
    top: -41px;
    height: 45px;
    background: #fff
}

header.colored {
    padding-top: 22px;
    padding-bottom: 28px;
    background: #16224e;
}

header.colored nav .line_hover:after {
    bottom: -33px
}

header.colored.small {
    padding-bottom: 20px;
    z-index:99999;
}

header.colored.small nav .line_hover:after {
    bottom: -25px
}

header.small {
    background: #16224e;
}

header.small nav .line_hover:after {
    bottom: -23px
}

.mainLogo a {
    position: relative
}

.mainLogo a .clone {
    display: block;
    position: absolute;
    left: 0;
    top: -6px
}

.langBlock {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    position: relative;
    top: 3px
}

.langBlock a {
    color: #fff;
    font-family: Montserrat, serif;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 15px;
    line-height: 18px
}

.langBlock a.active,
.langBlock a:hover {
    color: #f67c1f
}

.langBlock svg {
    margin: 0 16px
}

.articleBanner,
.careesBlock,
.contactsBanner {
    background: #16224e
}

.firstScreen {
    position: relative;
    z-index: 1;
    background-color: rgba(14, 26, 73, .7)
}

.firstScreen .text {
    color: #fff
}

.firstScreen .content {
    position: relative;
    max-width: 669px
}

.firstScreen .video {
    background-color: rgba(14, 26, 73, .7);
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-filter: brightness(.8);
    filter: brightness(.8)
}

.text {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 165%;
    color: #16224e;
}

.whiteText {
    color: #fff
}

.whiteText .text,
.whiteText h1,
.whiteText h2,
.whiteText h3,
.whiteText h4,
.whiteText h5,
.whiteText h6 {
    color: #fff
}

nav {
    padding-right: 1px
}

nav ol {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none
}

nav li {
    margin-right: 56px;
    margin-bottom: 0
}

nav li:before {
    display: none
}

nav li:last-child {
    margin-right: 0
}

nav a.active {
    color: #f67c1f
}

nav a:hover {
    color: #f67c1f
}

nav .line_hover:after {
    pointer-events: none;
    height: 6px;
    bottom: -15px;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    left: -2px;
    right: -2px;
    width: auto;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

header .mainLogo {
    margin-right: 31px
}

.pageNumber {
    position: absolute;
    left: 35px;
    bottom: 35px;
    text-align: center
}

.pageNumber span {
    font-family: Montserrat, serif;
    font-weight: 700;
    color: #fff;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase
}

.pageNumber svg {
    display: block;
    margin: 4.5px 0
}

.pageNumber.static {
    position: static;
    margin-left: 30px
}

.pageNumber.static svg {
    margin: -3.5px auto 2px
}

.pageNumber.static span {
    color: #16224e;
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px
}

.pageNumber.static span:first-child {
    color: #f67c1f
}

.scrollDown {
    width: 119px;
    height: 107px;
    position: absolute;
    right: 56px;
    bottom: 47px;
    cursor: pointer
}

.scrollDown svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto
}

.scrollDown .text {
    -webkit-animation: 10s animRotate linear infinite;
    animation: 10s animRotate linear infinite
}

.scrollDown .mouse circle {
    -webkit-animation: 2s bounceMouse ease-in infinite;
    animation: 2s bounceMouse ease-in infinite
}

.scrollDown:after {
    content: '';
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 1px;
    height: 60px;
    background: #f67c1f;
    top: 100%;
    margin-top: 20px;
    position: absolute
}

@-webkit-keyframes animRotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes animRotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes bounceMouse {
    10% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    60% {
        opacity: 1
    }
    80% {
        -webkit-transform: translateY(7px);
        transform: translateY(7px);
        opacity: 0
    }
    80.01% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    90% {
        opacity: 1
    }
}

@keyframes bounceMouse {
    10% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    60% {
        opacity: 1
    }
    80% {
        -webkit-transform: translateY(7px);
        transform: translateY(7px);
        opacity: 0
    }
    80.01% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    90% {
        opacity: 1
    }
}

.aboutBlock {
    margin-bottom: 123px
}

.aboutBlock .titleSVG {
    margin-top: 29px
}

.aboutBlock .row {
    padding-top: 57px
}

.titleSVG {
    display: table;
    position: relative;
    margin-right: 40px
}

.titleSVG a {
    position: absolute;
    top: 46%;
    right: -64px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index:9999;
}

.titleSVG span {
    position: absolute;
    top: 46%;
    right: -64px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index:9999;
}

.titleSVG.noMargin {
    margin-right: 0
}

.aboutSVG {
    position: absolute;
    left: -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50%
}

.aboutSVG svg,
.contactLottie svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index:999;
}

.aboutSVG:before,
.contactLottie:before {
    content: '';
    padding-top: 86%;
    display: block
}

.contactLottie {
    width: 710px;
    max-width: 100%;
    position: relative
}

.contactLottie svg {
    height: 123%!important;
    bottom: auto;
    top: -30%
}

.contactLottie:before {
    padding-top: 38.8%
}

.titleRow {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    margin-bottom: 80px;
    position: relative;
}

.titleRow.alignStart {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.titleRow.alignStart .rightColumn {
    padding-top: 13px
}

.titleRow h1,
.titleRow h2,
.titleRow h3,
.titleRow h4 {
    margin-bottom: 0
}

.titleRow .titleSVG {
    margin-right: 50px;
    top: 4px;
    -webkit-flex-shrink: 3;
    -ms-flex-negative: 3;
    flex-shrink: 3
}

.titleRow .titleSVG a {
    top: 50%;
    right: -51px
}

.titleRow .titleSVG.noMargin {
    margin-right: 0
}

.titleRow .titleSVG.noMargin a {
    top: 85% !important;
    transform: translateY(-100%);
    right: 1px
}

.titleRow a.bigPadding {
    padding-right: 35px
}

.titleRow .text {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
}

.titleRow .text h1,
.titleRow .text h2,
.titleRow .text h3,
.titleRow .text h4 {
    margin-bottom: 20px
}

.titleRow .text>:last-child {
    margin-bottom: 0
}

.titleRow.smallMargin {
    margin-bottom: 25px
}

.titleRow .goBack {
    display: block;
    position: absolute;
    left: -55px;
    top: 25px;
    width: 32px;
    height: 32px;
    background: url(./svg/arrow_link.svg) no-repeat center center;
    background-size: 65% auto;
    -webkit-transform: rotate(180deg)!important;
    -ms-transform: rotate(180deg)!important;
    transform: rotate(180deg)!important;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.titleRow .goBack:hover {
    background-position-x: 100%
}

.titleRow .titleSVG ol {
    text-align: left;
    max-width: 273px;
    padding: 0;
    margin-right: 0;
    margin-left: auto;
    margin-top: 34px
}

.titleRow .titleSVG ol li {
    font-size: 16px
}

.projectSliderItem {
    position: relative;
    width: 474px;
    height: 488px;
    overflow: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    background: rgba(0, 0, 0, .25)
}

.projectSliderItem .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.projectSliderItem .swiper-lazy {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
}

.projectSliderItem .swiper-lazy-loaded {
    opacity: 1;
    visibility: visible
}

.projectSliderItem .content {
    position: absolute;
    left: 0;
    right: 0;
    top: -webkit-calc(100% - 127px);
    top: calc(100% - 127px);
    z-index: 2;
    padding: 32px 32px 39px;
    -webkit-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -o-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    transition: all .6s cubic-bezier(.785, .135, .15, .86);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 127px;
    text-decoration: none;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.projectSliderItem .content:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(246, 124, 31, .8);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    z-index: -1;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    will-change: left, top, right, bottom;
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.projectSliderItem .content:active {
    -webkit-transform: scale(.98);
    -ms-transform: scale(.98);
    transform: scale(.98);
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.projectSliderItem .count {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 48%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 44px
}

.projectSliderItem .mainRow {
    position: relative;
    padding-left: 87px
}

.projectSliderItem h5 {
    margin: 0;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: .15px;
    height: 56px;
    overflow: hidden
}

.projectSliderItem:hover .content {
    top: 8px
}

.projectSliderItem:hover .content:before {
    left: 8px;
    right: 8px
}

.projectSliderItem .topRow {
    margin-top: 38px
}

.projectSliderItem .topRow span {
    font-family: Montserrat, serif;
    font-weight: 700;
    font-size: 60px;
    line-height: 75%;
    -webkit-text-stroke-width: .86px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: #fff;
    color: #fff;
    -webkit-text-stroke: 1px #fff
}

.projectSliderItem ul {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #16224e;
    list-style: none;
    padding-left: 39px;
    padding-top: 24px;
    padding-bottom: 2px
}

.projectSliderItem ul li {
    position: relative;
    padding-left: 48px;
    margin-bottom: 14px
}

.projectSliderItem ul li:last-child {
    margin-bottom: 0
}

.projectSliderItem ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 16px;
    height: 2px;
    background: #fff
}

/*.projectSliderItem ul li:nth-child(3)~li {*/
/*    display: none*/
/*}*/

.projectSliderItem ul {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.projectSliderItem .topRow {
    height: 0;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    position: relative;
    padding-right: 50px
}

.projectSliderItem .topRow:after {
    content: '';
    position: absolute;
    right: 13px;
    top: 24%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: url(./svg/arrow_link_white.svg) no-repeat center center
}

.projectSliderItem.active .topRow,
.projectSliderItem:hover .topRow {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 38px;
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s
}

.projectSliderItem.active ul,
.projectSliderItem:hover ul {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: .25s;
    -o-transition-delay: .25s;
    transition-delay: .25s
}

.projectSliderItem.active .content,
.projectSliderItem:hover .content {
    top: 0;
    height: 100%
}

.projectSliderItem.active .content::before,
.projectSliderItem:hover .content::before {
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px
}

.projectSlider .controllRow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 40px
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid #16224e;
    background: 0 0;
    opacity: 1;
    margin: 0 10px;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    cursor: pointer
}
@media screen and (max-width:767px) {
    .swiper-pagination-bullet {
        margin: 0 3px;
    }
}


.swiper-pagination-bullet:hover {
    border-color: #f67c1f;
    background: 0 0
}

.swiper-pagination-bullet-active {
    background: #16224e
}

.swiper-pagination {
    position: relative;
    margin: 0 13px
}

.swiper-pagination {
    display: table
}

.swiper-button-next,
.swiper-button-prev {
    position: relative;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0
}

.swiper-button-next svg,
.swiper-button-prev svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto
}

.swiper-button-next svg path,
.swiper-button-prev svg path {
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.swiper-button-next:hover svg path,
.swiper-button-prev:hover svg path {
    fill: #f67c1f
}

.swiper-button-prev svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

[class^=swiper-button] {
    outline: 0
}

[class^=swiper-button].swiper-button-disabled {
    opacity: 0
}

.teamSliderItem {
    width: 376px;
    height: 376px
}

.teamSliderItem .content {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    top: auto;
    bottom: 0;
    padding-bottom: 23px;
    height: 100%
}

.teamSliderItem .content:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000));
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, #000 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, #000 100%);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    height: 100%;
    -webkit-transform: translateY(80%);
    -ms-transform: translateY(80%);
    transform: translateY(80%);
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    z-index: -1
}

.teamSliderItem .content:before {
    display: none
}

.teamSliderItem .text>:last-child {
    margin-bottom: 0
}

.teamSliderItem .text {
    color: #fff;
    font-family: Roboto Slab, serif;
    font-size: 14px;
    line-height: 18px;
    padding-top: 23px;
    display: none;
    max-height: 239px;
    overflow: hidden
}

.teamSliderItem .mainRow {
    padding: 0;
    padding-bottom: 5px
}

.teamSliderItem .mainRow h5 {
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    height: auto;
    font-family: Roboto Slab, serif;
    font-weight: 400
}

.teamSliderItem .mainRow h5:first-child {
    font-weight: 700;
    color: #f67c1f
}

.teamSliderItem:hover .content:after {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.mainPage .projectsBlock {
    margin-bottom: 120px
}

.projectsBlock.teamBlock {
    margin-bottom: 187px
}

.teamBlock .projectSlider {
    margin-top: -11px
}

.teamBlock .projectSlider .controllRow {
    margin-top: 55px
}

.teamBlock .text {
    max-width: 376px
}

.teamBlock .titleRow {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.teamBlock .titleSVG {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 0 0 7%;
    margin-right: 87px;
    margin-bottom: 11px
}

.careesBlock {
    background: #16224e
}

.careesBlock .bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center
}

.careesBlock {
    text-align: center;
    min-height: 740px
}

.careesBlock .text {
    max-width: 572px;
    margin-bottom: 31px;
    margin-top: -13px
}

.careesBlock .text h2,
.careesBlock .text h3 {
    margin-bottom: 30px
}

.careesBlock .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.careesBlock .text,
.careesBlock .titleSVG {
    display: table;
    margin-left: auto;
    margin-right: auto
}

.careesBlock .titleSVG a {
    right: -56px
}

.contactsBlock {
    margin-top: 115px;
    margin-bottom: -16px;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}
.contactsBlock .container{
    position:relative;
    z-index:9991;
}

.contactsBlock .shape{
    transform: rotate(-25deg);
}


.contactsBlock .titleRow {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.contactsBlock .titleSVG {
    margin-top: 14px
}

.contactsBlock .shape {
    display: table;
    margin-left: auto;
    margin-right: 7%;
    margin-top: 6px
}

footer {
    padding-top: 24px;
    padding-bottom: 33px;
    margin-bottom: 2px;
    position: relative;
    z-index: 2
}

footer .bg {
    position: absolute;
    top: 0;
    left: 16px;
    right: 16px;
    bottom: 16px;
    background: #fff;
    /* 	background: #16224e; */
    z-index: -1
}

footer .logo {
    position: relative;
    top: 19px;
    left: -4px;
    max-width: 150px;
}

footer nav a {
    font-family: Montserrat, serif;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1px;
    color: #16224e;
}

footer nav span {
    font-family: Montserrat, serif;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1px;
    color: #16224e;
    cursor: pointer;
}

footer li {
    margin-right: 0!important;
    margin-bottom: 24px
}

footer ul:last-child li:first-child {
    margin-right: 5px!important;
}

footer li:last-child {
    margin-bottom: 0
}

footer ul {
    min-width: 270px;
    list-style: none
}

footer ul:last-child {
    text-align: right
}

footer .bottomRow {
    margin-top: 30px;
    color: #16224e
}

footer .bottomRow .column {
    min-width: 150px
}

footer .bottomRow .column:last-child {
    text-align: right
}

footer .bottomRow a {
    color: #16224e;
    font-size: 12px;
    line-height: 15px;
    font-family: Montserrat, serif;
}

footer .bottomRow span {
    color: #16224e;
    font-size: 12px;
    line-height: 15px;
    font-family: Montserrat, serif;
}

.logo svg {
    cursor: pointer;
}

.scrollTopBtn {
    position: absolute;
    top: -7px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    border: 2px solid #16224e;
    width: 48px;
    height: 48px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    z-index: 5;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    cursor: pointer
}

.scrollTopBtn svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto
}

.scrollTopBtn svg path {
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.scrollTopBtn:hover {
    border-color: #f67c1f
}

.scrollTopBtn:hover svg path {
    fill: #f67c1f
}

.projectsPage .projectsBlock .titleSVG {
    margin-right: 0
}

.projectsPage .titleRow {
    margin-bottom: 23px
}

.projectsPage .projectsBlock {
    margin-bottom: 79px
}

.projectsPage .contactsBlock .shape {
    margin-top: 63px
}

.projectsBlock .contentText {
    margin-bottom: 61px
}

.contentText {
    max-width: 50%
}

.contentText.moreWidth {
    max-width: 865px
}

.projectSingle .pageNumber {
    margin-right: 13px;
    margin-top: -12px
}

.swiper-button-next,
.swiper-button-prev {
    outline: 0
}

.articleBanner {
    position: relative;
    max-height: 640px;
    margin-top: -22px;
    min-height: 163px;
    margin-bottom: 75px
}

.articleBanner:before {
    content: '';
    padding-top: 45.5%;
    display: block
}

.articleBanner img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.articleContent {
    max-width: 764px;
    margin-bottom: 126px
}

.articleContent h3 {
    margin-bottom: 39px
}

.articleContent li,
.articleContent p,
.articleContent table {
    margin-bottom: 16px
}

.articleContent>:last-child {
    margin-bottom: 0
}

.articleContent h2+ol,
.articleContent h2+ul,
.articleContent h3+ol,
.articleContent h3+ul,
.articleContent h4+ol,
.articleContent h4+ul {
    margin-top: -5px
}

.imagesGrid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    opacity: 0;
    margin-bottom: 56px;
    width: 1160px
}

.imagesGrid img {
    display: block
}

.imagesGriditem {
    position: relative;
    width: -webkit-calc(60% - 27px);
    width: calc(60% - 27px);
    margin-right: 16px;
    max-height: 376px;
    margin-bottom: 18px
}

.imagesGriditem::before {
    content: '';
    padding-top: 26.25vw;
    display: block
}

.imagesGriditem img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.imagesGriditem:nth-child(4n+2) {
    width: -webkit-calc(40% + 11px);
    width: calc(40% + 11px);
    margin-right: 0
}

.imagesGriditem:nth-child(4n+3) {
    width: -webkit-calc(40% + 11px);
    width: calc(40% + 11px);
    margin-right: 16px
}

.imagesGriditem:nth-child(4n+4) {
    width: -webkit-calc(60% - 27px);
    width: calc(60% - 27px);
    margin-right: 0
}

.projectSingleSlider {
    margin-top: 96px;
    margin-bottom: 60px;
    width: 83vw;
}

.projectSingleSlider div[class^=swiper-button] {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 180px;
    height: 100%;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.projectSingleSlider .swiper-button-prev {
    left: 0;
    right: auto;
    background: -webkit-gradient(linear, left top, right top, color-stop(2.14%, #fff), color-stop(98.95%, rgba(255, 255, 255, 0)));
    background: -o-linear-gradient(left, #fff 2.14%, rgba(255, 255, 255, 0) 98.95%);
    background: linear-gradient(90deg, #fff 2.14%, rgba(255, 255, 255, 0) 98.95%)
}

.projectSingleSlider .swiper-button-prev svg {
    left: 29px;
    right: auto
}

.projectSingleSlider .swiper-button-next {
    left: auto;
    right: 0;
    background: -o-linear-gradient(178.72deg, #fff 2.14%, rgba(255, 255, 255, 0) 98.95%);
    background: linear-gradient(271.28deg, #fff 2.14%, rgba(255, 255, 255, 0) 98.95%)
}

.projectSingleSlider .swiper-button-next svg {
    left: auto;
    right: 29px
}

.projectSingleSlider svg {
    width: 21px;
    height: 21px
}

.projectSingleSliderItem {
    height: 266px;
    width: 33vw
}

.projectSingle h1 {
    word-break: break-word
}

.statistics {
    margin-top: 66px;
    padding-left: 14px;
    margin-bottom: 70px
}

.statisticsItem {
    -webkit-transition: 1s ease;
    -o-transition: 1s ease;
    transition: 1s ease;
    width: 182px;
    margin-right: 14px
}

.statisticsItem:last-child {
    margin-right: 0
}

.statisticsItem .number {
    position: relative;
    color: transparent!important
}

.statisticsItem .number span {
    position: absolute;
    padding-top: 5px;
    left: 0;
    top: 0;
    bottom: 0;
    font: inherit;
    color: #16224e
}

.statisticsItem .number span.active {
    opacity: 1!important
}

.statisticsItem .circle {
    font-family: Montserrat, serif;
    font-size: 32px;
    line-height: 100%;
    color: #16224e;
    font-weight: 600;
    position: relative;
    display: table;
    margin-bottom: 32px
}

.statisticsItem .prefix {
    margin-left: -8px
}

.statisticsItem .wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 60%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center
}

.statisticsItem img {
    display: block
}

.statisticsItem svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.statisticsItem p {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16.5789px;
    line-height: 19px;
    padding: 0 33px;
    text-align: center
}

.articleNavigationItem {
    background: #16224e;
    min-height: 164px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 0;
    margin-bottom: 16px
}

.articleNavigationItem:last-child {
    margin-bottom: 0
}

.articleNavigationItem .h2 {
    color: #fff;
    max-width: 60%;
    padding-bottom: 7px
}

.articleNavigationItem .titleRow {
    margin: 0
}

.articleNavigationItem .titleSVG {
    position: absolute;
    top: 50%!important;
    -webkit-transform: translateY(-50%)!important;
    -ms-transform: translateY(-50%)!important;
    transform: translateY(-50%)!important;
    right: 0
}

.articleNavigationItem .titleRow .titleSVG a {
    color: #fff
}

.articleNavigationItem a.arrow:before {
    background-image: url(./svg/arrow_link_white.svg);
    background-size: 16px auto
}

.articleNavigationItem.projects {
    background: #f67c1f
}

.articleNavigationItem .line_hover:after {
    background: #fff
}

.articleNavigationItem svg {
    margin-right: 0;
    margin-left: auto
}

.articleNavigationItem svg.mobile {
    display: none
}

.articleNavigationItem svg.mobile path {
    opacity: .2;
    stroke: none;
    fill: #fff;
    stroke-dasharray: 0;
    stroke-dashoffset: 0
}

.contactForm {
    max-width: 375px;
    margin-top: 31px;
    margin-left: 13px
}

.contactForm label {
    width: 100%
}

.contactForm .bottomRow {
    margin-top: 15px
}

.contactForm button {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    background: 0 0;
    border: 0;
    outline: 0;
    color: #f67c1f;
    padding-left: 0;
    cursor: pointer
}

.chosen-container {
    width: 100%!important
}

.chosen-container-single .chosen-single {
    font-size: 18px;
    font-family: Montserrat, serif;
    text-transform: none;
    line-height: 19px;
    letter-spacing: 0
}

.chosen-container-single .chosen-single {
    height: 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #16224e;
    border: 0;
    background: 0 0;
    padding: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 2px solid #16224e
}

.chosen-container-active.chosen-with-drop .chosen-single {
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.result-selected {
    color: #fff !important;
    background-color: #f67c1f;
}

.chosen-container .chosen-results li {
    font-size: 18px;
    font-family: Montserrat, serif;
    text-transform: none;
    line-height: 19px;
    color: #16224e;
    min-height: 40px;
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.chosen-container .chosen-results {
    padding-left: 0;
    padding-right: 0;
    margin: 0
}

.chosen-container-active.chosen-with-drop .chosen-single {
    border: 0
}

.chosen-container .chosen-drop {
    border: 1px solid #283b80
}

.chosen-container-single .chosen-search input[type=text] {
    font-size: 14px;
    line-height: 19px;
    font-family: "Jasan Wide", serif;
    height: 35px;
    border: none;
    border-bottom: 1px solid #16224e
}

.chosen-container .chosen-results li.highlighted {
    background: #f67c1f
}

.chosen-container-single .chosen-single div b {
    background: 0 0;
    position: relative;
    top: 50%;
}

.chosen-container-single .chosen-single div b:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(0);
    -ms-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    background: url(./svg/arrow_simple.svg) no-repeat center center;
    width: 20px;
    height: 20px
}

.chosen-container-single .chosen-drop {
    -webkit-border-radius: 0;
    border-radius: 0;
    margin-top: -2px
}

.chosen-container .chosen-results li.disabled-result {
    display: none!important
}

.contactPage .titleRow .text ol,
.contactPage .titleRow .text p,
.contactPage .titleRow .text table,
.contactPage .titleRow .text ul {
    max-width: 570px
}

.contactPage .titleRow .text h1,
.contactPage .titleRow .text h2,
.contactPage .titleRow .text h3 {
    margin-bottom: 32px
}

.contactsBanner {
    min-height: 640px;
    padding-top: 9px
}

.contactsBanner .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center
}

.contactPage .contactsBanner {
    margin-top: -16px
}

.contactsDataItem {
    position: relative;
    border-left: 12px solid #f67c1f;
    width: 473px;
    max-width: 100%;
    padding: 32px 36px 34px;
    z-index: 2;
    margin-bottom: 16px
}

.contactsDataItem:last-child {
    margin-bottom: 0
}

.contactsDataItem:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: .92;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    z-index: -1
}

.contactsDataItem ul {
    list-style: none
}

.contactsDataItem b {
    font-weight: 700
}

.contactsDataItem li {
    font-family: Montserrat, serif;
    font-style: normal;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: -.12px;
    margin-bottom: 1px
}

.contactsDataItem li:last-child {
    margin-bottom: 0
}

.contactsDataItem a {
    color: inherit;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s
}

.contactsDataItem a:hover {
    color: #f67c1f
}

.contactsDataItem .social {
    margin-right: 15px
}

.contactsDataItem.row {
    padding-bottom: 27px
}

.social a {
    display: block
}

.social a svg {
    display: block
}

.social a svg path,
.social a svg rect {
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease
}

.social a:hover svg path,
.social a:hover svg rect {
    stroke: #f67c1f
}

.social.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.social.flex li {
    margin-right: 46px
}

.social.flex li:last-child {
    margin-right: 0
}

.contactFormDone {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 50px;
    margin: auto;
    z-index: 50;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #16224e;
    width: 262px;
    display: none
}

.contactFormDone .text {
    color: #fff;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    padding: 16px;
    max-width: 262px
}

.contactFormDone .wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

.teamGrid {
    margin: -10px -10px 0
}

.teamGrid .teamSliderItem {
    margin: 0 8px 16px;
    width: -webkit-calc(100% / 3 - 16px);
    width: calc(100% / 3 - 16px)
}

.teamSliderItem .content:active {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.coreList {
    margin-top: 67px
}

.coreValues {
    text-align: center;
    padding-top: 27px;
    margin-bottom: 63px
}

.coreValues .lessWidth ol,
.coreValues .lessWidth p,
.coreValues .lessWidth table,
.coreValues .lessWidth ul {
    margin-left: auto;
    margin-right: auto
}

.coreListitem {
    padding: 0 5px;
    text-align: center
}

.coreListitem b {
    font-weight: 700
}

.coreListitem b:first-child {
    margin-bottom: 16px;
    display: block
}

.filterPosiiton ul {
    width: 360px;
    left: auto;
    top: 0;
    border: 1px solid transparent;
    padding-top: 17px;
    right: -16px;
}

.filterPosiiton.open ul {
    border-color: #16224e;
    -webkit-transition: border-color .5s;
    -o-transition: border-color .5s;
    transition: border-color .5s;
}

.filterPosiiton .hiddenTop {
    width: 100%!important;
    position: relative;
    font-family: Montserrat, serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px
}

.filterPosiiton .hiddenTop .close {
    position: absolute;
    top: 0;
    right: 14px;
    margin: auto;
    width: 20px;
    height: 20px;
    bottom: auto;
    background: url(./svg/close_orange.svg) no-repeat center center;
    cursor: pointer
}

.custom_dropdown .filterItem {
    padding-right: 0
}

.custom_dropdown li {
    text-align: left
}

.custom_dropdown .top {
    position: relative;
    line-height: 26px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding-right: 33px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.custom_dropdown .top:hover {
    color: #f67c1f
}

.custom_dropdown.open .arrow {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.titleRow .filterPosiiton {
    margin-top: 43px;
    margin-right: -3px
}

.filterItem {
    line-height: 26px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    padding-right: 33px!important;
    top: 0!important;
    -webkit-transform: none!important;
    -ms-transform: none!important;
    transform: none!important;
    /*display: -webkit-inline-box!important;*/
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
    width: auto!important;
    position: relative;
    margin-right: 27px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.filterItem:last-child {
    margin-right: 0
}

.filterItem .arrow,
.filterItem:after {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background: url(./svg/arrow_simple_orange.svg) no-repeat center center
}

.filterItem a {
    color: #16224e;
    text-decoration: none
}

.filterItem:after {
    content: ''
}

.filterItem:hover,
.filterItem:hover a {
    color: #f67c1f
}

.filterItem.active {
    font-weight: 700
}

.custom_dropdown .filterItem {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    top: 0
}

.custom_dropdown .top .filterItem {
    padding-right: 0!important
}

.custom_dropdown .top .filterItem:after {
    display: none
}

.teamPage .titleRow .rightColumn {
    margin-right: 27px
}

.teamPage .container:first-child {
    z-index: 2
}

.imagesStaticGrid {
    padding: 0 8px;
    margin-top: 32px;
    margin-bottom: 22px
}

.imagesStaticGridItem {
    position: relative;
    background: rgba(0, 0, 0, .25);
    max-height: 350px;
    overflow: hidden
}

.imagesStaticGridItem:before {
    content: '';
    padding-top: 76.5%;
    display: block
}

.imagesStaticGridItem .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.imagesStaticGridItem span {
    position: absolute;
    left: 0;
    bottom: 15px;
    padding-left: 19px;
    padding-right: 19px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    text-transform: uppercase
}

.positionsTitleRow {

    position: relative;
    z-index: 2
}

.positionsTitleRow h2,
.positionsTitleRow h3,
.positionsTitleRow h4 {
    margin-bottom: 0
}

.positionsTitleRow .filtersWrap {
    padding-top: 12px;
    padding-left: 20px
}

.custom_dropdown .top .filterItem {
    position: static
}

.attatchFile {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 40px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #c9ccd6;
    padding: 3px 5px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    margin-bottom: 37px;
    margin-top: -6px
}

.attatchFile:hover {
    border-color: #283b80
}

.attatchFile span {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: #283b80
}

.careerForm {
    margin-top: 43px;
    margin-left: 0
}

.careerForm .textarea {
    margin-top: 0
}

.file input {
    display: none
}

.file .names {
    display: none;
    width: 100%;
    text-align: center
}

.file.hasFile {
    border-color: #283b80
}

.file.hasFile:hover {
    border-color: #f67c1f
}

.file.hasFile>span {
    display: none
}

.file.hasFile .names {
    display: block
}

.mobileOnly {
    display: none
}

.careersPage .contactsBlock {
    margin-top: 81px
}

.imagesSlider .imagesStaticGridItem {
    width: 100%
}

.projectsInspire {
    text-align: center;
    margin-bottom: 68px
}

.text.mediumWidth ol,
.text.mediumWidth p,
.text.mediumWidth table,
.text.mediumWidth ul {
    margin-left: auto;
    margin-right: auto
}

.projectsInspireWrap {
    margin: 61px -10px 55px
}

.projectsInspireItem {
    background: #16224e;
    min-height: 771px;
    text-align: left;
    padding: 80px 35px 40px 40px;
    position: relative;
    margin-bottom: 16px
}

.projectsInspireItem:nth-child(2n+2) {
    -webkit-transform: translateY(55px);
    -ms-transform: translateY(55px);
    transform: translateY(55px)
}

.projectsInspireItem h3 {
    color: #f67c1f;
    line-height: 120%;
    margin-bottom: 0;
    min-height: 86px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.projectsInspireItem .top {
    padding-right: 120px;
    margin-bottom: 28px
}

.projectsInspireItem .count {
    position: absolute;
    top: 20px;
    right: 20px;
    -webkit-text-stroke-width: .86px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: #fff;
    color: #fff;
    -webkit-text-stroke: 1px #fff;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 104px;
    opacity: .2;
    line-height: 107%
}

.projectsInspireItem b,
.projectsInspireItem strong {
    letter-spacing: .1px
}

.projectsInspireItem ul {
    margin-top: 22px
}

.projectsInspireItem ul li {
    padding-left: 51px;
    letter-spacing: 0;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 18px
}

.projectsInspireItem ul li:last-child {
    margin-bottom: 0
}

.projectsInspireItem ul li:before {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 5px;
    background: url(./svg/bullet.svg) no-repeat center center;
    width: 24px;
    height: 24px
}

.timelineListBlock svg {
    display: table;
    margin-left: auto;
    margin-right: auto
}

.timelineList {
    list-style: none;
    text-align: left;
    position: relative;
    padding-top: 70px;
    padding-bottom: 115px
}

.timelineList:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #f67c1f;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    background: -webkit-gradient(linear, left top, left bottom, from(#f67c1f), color-stop(50%, #f67c1f), to(#fff));
    background: -o-linear-gradient(top, #f67c1f 0, #f67c1f 50%, #fff 100%);
    background: linear-gradient(to bottom, #f67c1f 0, #f67c1f 50%, #fff 100%)
}

.timelineList b {
    font-weight: 700;
    font-family: Montserrat, serif;
    font-style: normal;
    font-size: 18px;
    margin-bottom: 4px;
    display: block
}

.timelineList li {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 58.5px;
    position: relative
}

.timelineList li .circle {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 21px;
    height: 21px;
    background: #fff;
    border: 2px solid #f67c1f;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    left: 50%
}

.timelineList li>:last-child {
    margin-bottom: 0
}

.timelineList li:nth-child(2n+1) {
    padding-left: -webkit-calc(50% + 60px);
    padding-left: calc(50% + 60px)
}

.timelineList li:nth-child(2n+2) {
    padding-right: -webkit-calc(50% + 60px);
    padding-right: calc(50% + 60px);
    text-align: right
}

.timelineVision {
    margin-bottom: 12px
}

.timelineVision .text {
    margin-bottom: 80px
}

.ethosText {
    margin-bottom: 71px
}

.ethosText b,
.ethosText strong {
    font-weight: 700
}

.ethosText strong {
    color: #f67c1f
}
/*.aboutPage{*/
/*    padding: 8px 0 !important;*/
/*}*/

.aboutPage .projectsBlock.teamBlock {
    margin-bottom: 19px
}

.projectSinglePage .pageNumber.static {
    margin-right: 14px;
    margin-top: -10px;
    margin-bottom: 10px
}

.privacyContent {
    margin-top: -15px
}

.privacyContent .text {
    max-width: 860px
}

.contactsBlock.small {
    margin-bottom: -140px;
    margin-top: -36px
}

.page404 .articleBanner {
    margin-top: -6px;
    margin-bottom: 0
}

.titleRow .text a.arrow {
    margin-top: 13px
}

.titleSVG svg path {
    opacity: 1;
    stroke: #16224e;
    stroke-width: .5;
    stroke-dasharray: 600;
    stroke-dashoffset: 600;
    stroke-opacity: .2;
    fill: transparent!important
}

.rightColumn.aos-animate svg path,
.titleSVG.aos-animate svg path {
    stroke-dashoffset: 0;
    -webkit-transition: stroke-dashoffset 2.5s ease-in;
    -o-transition: stroke-dashoffset 2.5s ease-in;
    transition: stroke-dashoffset 2.5s ease-in
}

.articleNavigationItem .titleSVG svg path,
.whiteText .titleSVG svg path {
    stroke: #fff
}

footer .bottomRow a {
    text-decoration: none
}

footer .bottomRow a:hover {
    color: #f67c1f
}

#video_html video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    opacity: 0
}

.articleNavigationItem svg.mobile path {
    fill: #fff!important;
    stroke: none!important
}

.rotateWords {
    position: relative;
    -webkit-perspective: 300px;
    perspective: 300px;
    display: inline-block
}

.rotateWords span:not(.default) {
    position: absolute;
    left: 0;
    top: 0
}

.rotateWords span {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    display: inline-block
}

.rotateWords .is-hidden {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    -webkit-animation: cd-rotate-1-out 1.2s;
    animation: cd-rotate-1-out 1.2s
}

.rotateWords .is-visible {
    opacity: 1;
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    -webkit-animation: cd-rotate-1-in 1.2s;
    animation: cd-rotate-1-in 1.2s
}

@-webkit-keyframes cd-rotate-1-out {
    0% {
        -webkit-transform: rotateX(0);
        -ms-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1
    }
    35% {
        -webkit-transform: rotateX(-40deg);
        -ms-transform: rotateX(-40deg);
        transform: rotateX(-40deg);
        opacity: 1
    }
    65% {
        opacity: 0
    }
    100% {
        -webkit-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        transform: rotateX(180deg);
        opacity: 0
    }
}

@keyframes cd-rotate-1-out {
    0% {
        -webkit-transform: rotateX(0);
        -ms-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1
    }
    35% {
        -webkit-transform: rotateX(-40deg);
        -ms-transform: rotateX(-40deg);
        transform: rotateX(-40deg);
        opacity: 1
    }
    65% {
        opacity: 0
    }
    100% {
        -webkit-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        transform: rotateX(180deg);
        opacity: 0
    }
}

@-webkit-keyframes cd-rotate-1-in {
    0% {
        -webkit-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        transform: rotateX(180deg);
        opacity: 0
    }
    35% {
        -webkit-transform: rotateX(120deg);
        -ms-transform: rotateX(120deg);
        transform: rotateX(120deg);
        opacity: 0
    }
    65% {
        opacity: 0
    }
    100% {
        -webkit-transform: rotateX(360deg);
        -ms-transform: rotateX(360deg);
        transform: rotateX(360deg);
        opacity: 1
    }
}

@keyframes cd-rotate-1-in {
    0% {
        -webkit-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        transform: rotateX(180deg);
        opacity: 0
    }
    35% {
        -webkit-transform: rotateX(120deg);
        -ms-transform: rotateX(120deg);
        transform: rotateX(120deg);
        opacity: 0
    }
    65% {
        opacity: 0
    }
    100% {
        -webkit-transform: rotateX(360deg);
        -ms-transform: rotateX(360deg);
        transform: rotateX(360deg);
        opacity: 1
    }
}

.aos.fadeDone {
    opacity: 1;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    -webkit-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-timing-function: cubic-bezier(.25, .46, .45, .94);
    -o-transition-timing-function: cubic-bezier(.25, .46, .45, .94);
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

.filterPosiiton.down ul {
    top: 100%
}

.filterPosiiton.down li a {
    padding-left: 10px;
    padding-right: 10px;
    text-align: left!important;
    display: block
}

.filterPosiiton.smallWidth ul {
    width: 100%;
    min-width: 150px;
    padding-top: 20px;
    padding-bottom: 20px
}

.filterPosiiton.borderMobile .filterPosiiton {
    display: block
}

.filterPosiiton.borderMobile .filterPosiiton .arrow {
    margin-left: 15px;
    position: relative;
    background: url(./svg/arrow_simple_orange.svg) no-repeat center center;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0)
}

.filterPosiiton.borderMobile .filterPosiiton ul {
    position: relative;
    left: 0;
    right: 0;
    border-left: 0;
    border-right: 0
}

.filterPosiiton.borderMobile .filterPosiiton.open .arrow {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.filterPosiiton.borderMobile .filterPosiiton.open:last-child {
    margin-bottom: -34px
}

.articleBanner.small {
    margin-top: 0;
    margin-bottom: 0;
    max-height: 385px;
    min-height: 274px
}

.articleBanner.small:before {
    padding-top: 33.5%
}

@media screen and (min-width:1381px) {
    /*.titleRow.bigTitle .text h2 {*/
    /*    font-size: 48px*/
    /*}*/
    .titleRow.bigTitle .text h1 {
        font-size: 46px;
        line-height: 50px
    }
    .teamPage .titleRow.bigTitle .text h1 {
        margin-bottom: 26px
    }
}

@media screen and (max-width:1380px) {
    .h1,
    h1 {
        font-size: 44px
    }
    .h2,
    h2 {
        font-size: 42px
    }
    .scrollDown {
        right: 40px
    }
}

@media screen and (max-width:1276px) {
    .imagesGrid {
        width: -webkit-calc(100vw - 120px - 32px);
        width: calc(100vw - 120px - 32px)
    }
}

@media screen and (max-width:1280px) {
    .h1,
    h1 {
        font-size: 40px
    }
    .h2,
    h2 {
        font-size: 38px
    }
    .scrollDown {
        right: 30px
    }
    nav li {
        margin-right: 40px
    }
    .teamBlock .titleSVG {
        -webkit-box-flex: initial;
        -webkit-flex: initial;
        -ms-flex: initial;
        flex: initial;
        padding: 0;
        margin-right: 40px
    }
    .accordion .content {
        padding-left: 60px!important;
        padding-right: 60px!important
    }
    .accordion .banner {
        margin-left: -60px!important;
        margin-right: -60px!important
    }
}

@media screen and (max-width:1180px) {
    .accordion .leftColumn {
        padding-right: 60px!important
    }
    .accordion .content {
        padding-left: 40px!important;
        padding-right: 40px!important
    }
    .accordion .banner {
        margin-left: -40px!important;
        margin-right: -40px!important
    }
    .filterItem {
        margin-right: 15px
    }
    nav li {
        margin-right: 30px
    }
    .accordion .leftColumn {
        width: -webkit-calc(100% - 375px);
        width: calc(100% - 375px)
    }
    .accordion .rightColumn {
        width: 300px
    }
}

@media screen and (min-width:992px) {
    nav li {
        -webkit-box-ordinal-group: 1!important;
        -webkit-order: 0!important;
        -ms-flex-order: 0!important;
        order: 0!important;
        -webkit-transition-delay: 0s!important;
        -o-transition-delay: 0s!important;
        transition-delay: 0s!important
    }
}

@media screen and (max-width:991px) {
    .accordion .leftColumn,
    .accordion .rightColumn {
        width: 100%!important
    }
    .contactForm {
        max-width: 100%
    }
    .accordion .leftColumn {
        margin-bottom: 56px
    }
    .scrollDown {
        right: 12px
    }
    .h1,
    h1 {
        font-size: 36px;
        line-height: 120%;
        margin-bottom: 40px
    }
    .h2,
    h2 {
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 19px
    }
    .container {
        padding-left: 0px;
        padding-right: 0px
    }
    .pageNumber:not(.static) {
        display: none
    }
    .pageNumber.static {
        position: absolute;
        bottom: auto;
        top: 15px;
        right: 28px;
        left: auto;
        margin: 0
    }
    .pageNumber.static span {
        font-size: 12.5px;
        line-height: 15px
    }
    .pageNumber.static svg {
        margin: -7.5px auto -4px
    }
    .wrapper {
        padding: 85px 8px 8px;
    }
    .wrapper.mainPage {
        padding-top: 8px
    }
    .wrapper.mainPage {
        padding-top: 8px
    }
    a.arrow {
        padding-right: 36px
    }
    .titleRow {
        margin-bottom: 48px
    }
    .projectSliderItem {
        width: -webkit-calc(100vw * .4);
        width: calc(100vw * .4)
    }
    .projectSliderItem .count {
        font-size: 40px;
        top: 0;
        line-height: 40px;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
    .projectSliderItem .content {
        padding-left: 17px;
        padding-right: 17px
    }
    .projectSliderItem .content h5 {
        letter-spacing: 0
    }
    .projectSliderItem .mainRow {
        padding-left: 61px
    }
    .projectSliderItem .content {
        top: -webkit-calc(100% - 119px);
        top: calc(100% - 119px)
    }
    .projectSlider .controllRow {
        margin-top: 30px
    }
    .aboutBlock.fullHeight {
        padding-bottom: 0;
        padding-top: 0
    }
    .teamSliderItem .mainRow {
        padding-left: 0
    }
    .teamSliderItem .content {
        height: auto!important;
        top: auto!important;
        padding-left: 30px;
        padding-bottom: 14px;
        -webkit-transition: 0s;
        -o-transition: 0s;
        transition: 0s
    }
    .teamSliderItem .stub {
        display: none
    }
    .teamBlock .projectSlider .controllRow {
        margin-top: 30px
    }
    .careesBlock .container {
        padding-left: 20px;
        padding-right: 20px
    }
    .contactsBlock {
        margin-top: 86px
    }
    .firstScreen .container,
    footer .container {
        padding-left: 20px;
        padding-right: 20px
    }
    footer .bg {
        left: 12px;
        right: 12px;
        bottom: 12px
    }
    footer .logo svg {
        width: 150px
    }
    .menu-btn {
        display: block
    }
    header .langBlock,
    header nav {
        display: none
    }
    .mainLogo svg {
        width: 139px
    }
    .mainLogo a .clone {
        top: -15px
    }
    header {
        top: 8px;
        padding-top: 22px;
        padding-left: 21px;
        border-left-width: 12px;
        border-right-width: 12px
    }
    header.show {
        top: 8px
    }
    .langBlock svg {
        margin: 0 12px
    }
    header.colored {
        padding-top: 15px;
        padding-bottom: 19px
    }
    .articleContent h3 {
        margin-bottom: 35px
    }
    .projectSingleSlider {
        margin-top: 24px;
        margin-bottom: 50px
    }
    .projectSingle .container {
        padding-bottom: 0
    }
    .articleBanner {
        margin-top: 0;
        margin-bottom: 59px
    }
    .projectSingleSlider div[class^=swiper-button] {
        display: none
    }
    .imagesGrid {
        width: -webkit-calc(100vw - 16px);
        width: calc(100vw - 16px);
        margin-left: -12px;
        margin-right: -12px;
        margin-bottom: 44px
    }
    .articleContent {
        margin-bottom: 111px
    }
    .contactsBanner {
        min-height: initial
    }
    .filtersWrap,
    .positionsTitleRow .filtersWrap {
        width: 100%;
        padding-left: 0
    }
    .aboutPage .articleBanner {
        margin-top: -7px;
        margin-bottom: 34px
    }
}

@media screen and (max-width:840px) {
    .contentText {
        max-width: 100%;
    }

    .careesBlock .text {
        max-width: initial
    }
    .fullHeight {
        min-height: initial
    }
    .row .w50 {
        width: -webkit-calc(100% - 16px);
        width: calc(100% - 16px)
    }
    .aboutSVG {
        position: relative;
        width: 700px;
        top: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        height: auto;
        max-width: 124vw;
        left: -35%;
        margin-top: -6%;
        margin-bottom: -22%
    }
    .aboutBlock {
        display: block;
        padding-top: 0
    }
    .aboutBlock .container {
        padding-top: 0
    }
    .titleSVG svg {
        height: 60px;
        width: auto;
        position: relative;
        left: -3px
    }
    .titleSVG a {
        right: auto;
        left: 0;
        bottom: 8px;
        top: auto;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
    .titleRow {
        display: block
    }
    .projectsBlock {
        padding-bottom: 45px
    }
    .projectsBlock .container {
        position: static
    }
    .projectSliderItem {
        width: -webkit-calc(100vw * .75);
        width: calc(100vw * .75)
    }
    .row .w25,
    .row .w33,
    .row .w50 {
        margin-left: 15px;
        margin-right: 15px
    }
    .aboutBlock .titleSVG {
        margin-top: 37px
    }
    .aboutBlock {
        margin-bottom: 24px
    }
    .projectsBlock {
        margin-bottom: 103px
    }
    .mainPage .projectsBlock:not(.teamBlock) {
        margin-bottom: 103px
    }
    .mainPage .teamBlock,
    .teamBlock {
        margin-bottom: 133px
    }
    .titleRow .text h2,
    .titleRow .text h3,
    .titleRow .text h4 {
        margin-bottom: 26px
    }
    .teamBlock .projectSlider {
        margin-top: -9px
    }
    .teamSliderItem {
        height: 335px
    }
    .careesBlock {
        text-align: left
    }
    .careesBlock .text h2,
    .careesBlock .text h3 {
        margin-bottom: 22px
    }
    .careesBlock .text,
    .careesBlock .titleSVG {
        display: block
    }
    .careesBlock .text {
        padding-right: 22px
    }
    .careesBlock .text h2,
    .careesBlock .text h3 {
        margin-right: -22px
    }
    .careesBlock {
        min-height: initial;
        padding-top: 51px;
        padding-bottom: 31px
    }
    .contactsBlock .titleSVG {
        margin-top: 45px
    }
    .contactsBlock .titleSVG svg {
        left: 0
    }
    .titleRow .titleSVG.noMargin a {
        top: auto;
        right: auto
    }
    .contactsBlock {
        margin-bottom: -6px
    }
    .contactsBlock .shape {
        margin-left: -50vw;
        width: 150vw;
        margin-top: 81px;
        max-width: 1100px
    }
    .firstScreen {
        padding-top: 138px;
        padding-bottom: 140px
    }
    .titleRow .text {
        max-width: initial
    }
    .titleSVG svg {
        max-width: -webkit-calc(100vw - 40px);
        max-width: calc(100vw - 40px)
    }
    .teamBlock {
        padding-bottom: 57px
    }
    .projectsPage .projectsBlock .titleSVG {
        position: static;
        margin-bottom: -8px
    }
    .projectsPage .projectsBlock .titleRow {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
    .projectsPage .projectsBlock .titleRow .text {
        padding-right: 0;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }
    .projectsPage .titleSVG svg {
        left: 0
    }
    .projectSingle .titleRow {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
    .projectSingle .titleRow .text {
        padding-right: 0;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }
    .projectsPage .titleRow {
        margin-bottom: 19px
    }
    .projectsPage .projectsBlock .contentText {
        margin-bottom: 32px
    }
    .projectsPage .projectsBlock {
        margin-bottom: 53px;
        padding-bottom: 0
    }
    .titleRow .text {
        padding-right: 0 !important;
    }
    .projectSingle .titleRow {
        margin-bottom: 22px
    }
    .articleNavigationItem .titleSVG {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        top: 0;
        position: relative
    }
    .articleNavigationItem .titleSVG a {
        position: relative;
        top: 0;
        margin-top: 15px
    }
    .articleNavigationItem {
        min-height: 318px;
        position: relative;
        display: block;
        padding-bottom: 58px
    }
    .articleNavigationItem svg {
        display: none;
        position: absolute;
        top: 32px!important;
        left: 23px!important;
        height: auto!important;
        max-width: 100%
    }
    .articleNavigationItem svg.mobile {
        display: block
    }
    .articleNavigationItem .container,
    .articleNavigationItem .titleRow,
    .articleNavigationItem .titleSVG {
        position: static
    }
    .articleNavigationItem .titleRow {
        display: block;
        margin-bottom: 0
    }
    .articleNavigationItem .h2 {
        max-width: initial;
        padding-right: 40px;
        min-height: 93px
    }
    .articleNavigationItem .container {
        margin-top: 98px;
        margin-bottom: 0;
        padding-left: 20px;
        padding-right: 20px
    }
    .articleNavigationItem .titleSVG a {
        margin-top: 35px
    }
    .articleNavigationItem.team svg {
        left: 18px!important
    }
    .articleNavigation {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
    .articleNavigationItem.projects {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        margin-bottom: 8px
    }
    .articleNavigationItem {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        width: 100%;
        margin-bottom: 8px
    }
    .projectSingle {
        margin-bottom: -7px
    }
    .contactPage .titleRow {
        padding-top: 50px
    }

    .contactPage .text h1{
        margin-top:30px;
    }
    .contactPage .text p{
        display:none;
    }

    .projectsPage .projectsBlock .titleSVG,
    .titleRow.main .titleSVG {
        position: static;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
    .projectsPage .projectsBlock .titleSVG svg,
    .titleRow.main .titleSVG svg {
        position: absolute;
        left: 0;
        top: 5px;
        transform: translateY(0);
    }
    .projectsPage .projectsBlock .titleSVG svg,
    .titleRow.main .titleSVG svg {
        left: 0
    }
    .titleRow .rightColumn {
        position: static;
        -webkit-transform: none!important;
        -ms-transform: none!important;
        transform: none!important
    }
    .titleRow .titleSVG ol {
        margin-top: 0;
        margin-bottom: 0;
        max-width: initial
    }
    .projectsPage .projectsBlock .titleSVG {
        position: absolute
    }
    .titleRow.main {
        padding-top: 50px
    }
    .contactForm {
        margin-top: 14px;
        margin-left: 0
    }
    .contactPage .contactsBanner {
        margin-top: -28px
    }
    .titleRow .filterPosiiton {
        display: none
    }
    .teamPage .titleRow h1 {
        width: 60%
    }
    .teamGrid {
        margin-top: -34px
    }
    .teamGrid .teamSliderItem {
        width: -webkit-calc(100% / 2 - 16px);
        width: calc(100% / 2 - 16px);
        margin-left: 8px;
        margin-right: 8px
    }
    .teamGrid {
        margin-left: -8px;
        margin-right: -8px
    }
    .coreListitem {
        width: 100%!important;
        margin-bottom: 30px!important
    }
    .coreListitem:last-child {
        margin-bottom: 0!important
    }
    .coreList {
        margin-top: 30px
    }
    .coreValues {
        padding-top: 19px;
        margin-bottom: 85px
    }
    .careesBlock .titleSVG {
        position: relative;
        left: 0;
        top: 0
    }
    .contactsBlock {
        margin-top: 46px
    }
    .projectsBlock .titleRow {
        position: static
    }
    .projectsBlock .titleRow .titleSVG {
        position: absolute;
        top: auto;
        bottom: 0
    }
    .mainPage .contactsBlock {
        margin-top: 86px
    }
    .filtersWrap.desktopOnly {
        display: none
    }
    .filterPosiiton.mobileOnly {
        display: block
    }
    .filterPosiiton.borderMobile {
        border: 1px solid #16224e;
        padding-right: 15px;
        margin-left: 0;
        margin-right: 0;
        display: block;

    }
    .filterPosiiton.borderMobile .top {
        height: 54px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }
    .filterPosiiton.borderMobile ul {
        width: auto;
        left: -1px;
        right: -1px;
        top: -1px;
        min-height: 54px
    }
    .custom_dropdown .top .arrow {
        top: -2px;
        background: url(./svg/close_orange.svg) no-repeat center center;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }
    .h4,
    h4 {
        font-size: 28px
    }
    .filterPosiiton.borderMobile {
        margin-top: 39px
    }
    .filterPosiiton.borderMobile .top {
        letter-spacing: 1px
    }
    .accordionsWrap {
        width: auto!important;

    }
    .careersPage .contactsBlock .shape {
        margin-top: -15px;
        -webkit-transform: translateY(25%);
        -ms-transform: translateY(25%);
        transform: translateY(25%)
    }
    .titleRow.main br {
        display: none
    }
    .aboutPage>.container:first-child {
        padding-bottom: 0
    }
    .aboutPage .coreValues {
        margin-bottom: 0
    }
    .projectsInspire .text h3 {
        margin-bottom: 21px
    }
    .projectsInspireWrap {
        margin: 78px -12px 55px;
    }
    .projectsInspireItem {
        margin-left: 0!important;
        margin-right: 0!important;
        width: 100%!important;
        padding: 108px 20px 65px
    }
    .projectsInspireItem .top {
        padding-right: 80px
    }
    .projectsInspireItem h3 {
        font-size: 28px;
        min-height: initial;
        display: inline
    }
    .projectsInspireItem .count {
        top: 0;
        right: 0
    }
    .projectsInspireItem ul li {
        font-weight: 300;
        font-size: 18px;
        padding-left: 23px
    }
    .projectsInspireItem ul li:before {
        top: 7px;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        left: -1px;
        background: url(./svg/bullet.svg) no-repeat center center;
        width: 10px;
        height: 10px;
        background-size: contain
    }
    .projectsInspireItem {
        min-height: initial;
        margin-bottom: 8px!important
    }
    .projectsInspireItem:nth-child(2n+2) {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
    .timelineList li:nth-child(2n+1) {
        padding-left: -webkit-calc(50% + 39px);
        padding-left: calc(50% + 39px)
    }
    .timelineList li:nth-child(2n+2) {
        padding-right: -webkit-calc(50% + 39px);
        padding-right: calc(50% + 39px)
    }
    .timelineList li {
        line-height: 151.2%;
        margin-bottom: 37px
    }
    .timelineList li:before {
        top: 14px
    }
    .timelineList {
        padding-top: 79px;
        padding-bottom: 159px
    }
    .projectSingle {
        margin-top: -33px
    }
    .privacyContent .text p,
    .privacyContent .text table {
        margin-bottom: 14px
    }
    .privacyPage .contactsBlock {
        display: none
    }
    .privacyContent {
        margin-bottom: 11px
    }
    .page404 .articleBanner {
        margin-top: -28px
    }
    .articleNavigationItem .titleSVG {
        max-width: 70%
    }
    .contactLottie {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
    .contactLottie svg {
        width: 215%!important;
        left: -80%!important;
        height: 216%!important;
        bottom: auto!important;
        top: -113%!important
    }
    .aboutSVG svg {
        width: 215%!important;
        left: -77%!important;
        top: -30%!important;
        height: 115%!important
    }
    .projectSliderItem .content:active {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
    .custom_dropdown.open>.top .arrow {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0)
    }
    .wrapper>.container:first-child .rightColumn.aos-init,
    .wrapper>.container:first-child .titleSVG.aos-init {
        opacity: 1
    }
    .wrapper>.container:first-child .rightColumn.aos-init svg path,
    .wrapper>.container:first-child .titleSVG.aos-init svg path {
        stroke-dashoffset: 0;
        -webkit-transition: stroke-dashoffset 2.5s ease-in;
        -o-transition: stroke-dashoffset 2.5s ease-in;
        transition: stroke-dashoffset 2.5s ease-in
    }
}

@media screen and (max-width:768px) {
    .projectSinglePage .titleSVG {
        margin-bottom: -3px
    }
    .projectSinglePage .bigTitle .titleSVG svg {
        height: 43px;
        left: 2px
    }
    .titleSVG svg {
        height: 43px
    }
    .titleRow.main {
        padding-top: 40px
    }
    .contactsBlock .titleSVG svg {
        height: 49px
    }
    .aboutBlock .titleSVG svg,
    .careesBlock .titleSVG svg,
    .projectsBlock .titleSVG svg {
        height: 49px;
        left: 0
    }
}

@media screen and (max-width:640px) {
    .projectSingleSliderItem {
        height: 38.15vw;
        min-height: 143px
    }
    .imagesGriditem {
        width: 100%!important;
        margin-right: 0!important
    }
    .imagesGriditem:before {
        padding-top: 53.25vw
    }
    .imagesGriditem {
        min-height: 201px;
        margin-bottom: 9px
    }
    .imagesGriditem:nth-child(2n+2):before {
        padding-top: 75.75vw
    }
    .statistics {
        padding-left: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }
    .statistics .statisticsItem {
        width: 50%;
        margin-right: 0;
        text-align: center;
        margin-bottom: 13px
    }
    .statistics .statisticsItem .circle {
        margin-left: auto;
        margin-right: auto
    }
    .teamGrid .teamSliderItem {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 8px
    }
    .teamGrid {
        margin-left: -12px;
        margin-right: -12px
    }
    .teamGrid .teamSliderItem {
        height: 359px
    }
    .teamGrid .teamSliderItem .mainRow {
        padding-bottom: 10px
    }
    .coreValues .h3,
    .coreValues h3 {
        line-height: 123%;
        margin-bottom: 22px
    }
    .coreValues .text {
        font-size: 22px;
        line-height: 160%
    }
    .coreValues .text p {
        margin-bottom: 24px
    }
    .coreValues .text p:last-child {
        margin-bottom: 0
    }
}

@media screen and (max-width:540px) {
    .projectSlider:not(.projectSingleSlider) .swiper-container {
        width: 100%!important
    }
    .projectSliderItem {
        width: -webkit-calc(100vw - 23px);
        width: calc(100vw - 23px)
    }
    footer ul:last-child li:first-child {
         margin-right: 0!important;
    }
    footer .row {
        display: block;
        text-align: center
    }
    footer .bottomRow .column:last-child,
    footer ul:last-child {
        text-align: center
    }
    footer nav {
        position: relative;
        padding-top: 96px
    }
    footer nav .logo {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 12px;
        left: 50%;
        margin-left: -90px;
    }
    footer nav .logo svg {
        width: 103px
    }
    footer nav ul {
        margin-bottom: 24px
    }
    footer nav ul:last-child {
        margin-bottom: 0
    }
    footer .bottomRow {
        margin-top: 54px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }
    footer .bottomRow span {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }
    footer .bottomRow .column {
        margin-bottom: 11px
    }
    footer .bottomRow .column:last-child {
        margin-bottom: 17px
    }
    footer {
        padding-top: 5px;
        padding-bottom: 49px
    }
    .projectSingle h1 br {
        display: none
    }
    .projectSingleSliderItem {
        width: 68vw
    }
    .contactsBanner .container {
        padding: 0
    }
    .contactsBanner .bg {
        position: static;
        min-height: 163px;
        margin-bottom: 39px
    }
    .contactsBanner .bg:before {
        content: '';
        padding-top: 43vw;
        display: block
    }
    .contactsDataItem {
        padding: 0 40px 0 12px;
        margin-bottom: 75px;
        border: 0
    }
    .contactsDataItem:before {
        display: none
    }
    .contactsDataItem:last-child {
        margin-bottom: 7px
    }
    .contactsDataItem.row {
        padding-right: 0
    }
    .social.flex li {
        margin-right: 30px
    }
    .social a svg {
        height: 24px;
        width: auto
    }
    .contactsDataItem .social {
        margin-right: 21px
    }
    .contactPage .contactsBanner {
        margin-top: -36px
    }
    .timelineVision .text {
        margin-bottom: 30px
    }
    .projectSliderItem.active .topRow,
    .projectSliderItem:hover .topRow {
        margin-top: 0;
        min-height: 80px
    }
    .projectSliderItem .topRow span {
        font-size: 45px;
        word-break: break-word
    }
    .projectSliderItem .topRow:after {
        right: 0
    }
    .contactsBanner {
        background: 0 0
    }
    .articleNavigationItem .titleSVG {
        max-width: initial
    }
}

@media screen and (max-width:400px) {
    .projectSliderItem .topRow span {
        font-size: 38px
    }
}

@media screen and (max-width:991px) and (max-height:680px) {
    .mobile_row {
        padding-top: 70px
    }
    .mobile_row nav a {
        font-size: 38px;
        line-height: 40px
    }
    .mobile_row nav li {
        margin-bottom: 11px
    }
    .mobile_row {
        padding-top: 50px
    }
}

@media screen and (max-width:991px) and (max-height:490px) {
    .mobile_row .langBlock {
        margin-top: 20px
    }
    .mobile_row nav a {
        font-size: 32px;
        line-height: 30px
    }
}

@media screen and (max-width:480px) and (max-height:670px) {
    .firstScreen {
        min-height: -webkit-calc(100vh - 80px);
        min-height: calc(100vh - 80px);
        padding-top: 60px;
        padding-bottom: 100px
    }
    .firstScreen .h1,
    .firstScreen h1 {
        margin-bottom: 25px
    }
}

.accordionsWrap {
    width: 100%;
    display: block
}

.accordion {
    width: 100%;
    position: relative;
    z-index: 1;
    opacity: 1;
    margin-bottom: 16px;
    counter-increment: accordion;
    background: rgba(22, 34, 78, .04)
}

.accordion .top {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 18px;
    line-height: 150%;
    min-height: 100px;
    padding: 14px 80px 12px 48px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.accordion .top h5 {
    margin: 0;
    font-weight: 600
}

.accordion .top .arrow {
    position: absolute;
    width: 22px;
    height: 22px;
    right: 53px;
    top: 50%;
    background: url(./svg/close_orange.svg) no-repeat center center;
    -webkit-transform: translateY(-50%) rotate(-45deg);
    -ms-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s
}

.accordion .top h5 {
    font-size: 22px;
    font-weight: 700
}

.accordion .content {
    display: none;
    padding: 0 103px 0;
    position: relative;
    z-index: 1
}

.accordion.open {
    background: rgba(22, 34, 78, .12)
}

.accordion.open .arrow {
    -webkit-transform: translateY(-50%) rotate(0);
    -ms-transform: translateY(-50%) rotate(0);
    transform: translateY(-50%) rotate(0)
}

.accordion .text:last-child {
    margin-bottom: 0
}

.accordion .top p {
    margin-bottom: 0;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px
}

.accordion .top p:last-child {
    margin-bottom: 0
}

.accordion .content {
    font-size: 18px;
    line-height: 150%;
    margin: 0;
    letter-spacing: 0!important;
    padding-bottom: 88px;
    padding-top: 4px
}

.accordion .leftColumn {
    width: -webkit-calc(100% - 375px);
    width: calc(100% - 375px);
    padding-right: 117px
}

.accordion .rightColumn {
    width: 375px
}

.accordion .h3,
.accordion h3 {
    margin-bottom: 40px
}

.accordion .leftColumn .h3,
.accordion .leftColumn h3 {
    margin-left: -4px
}

.accordion .text ol,
.accordion .text p,
.accordion .text table {
    margin-bottom: 14px
}

.accordion .text li,
.accordion .text ul {
    margin-bottom: 15px
}

.accordion .text ol,
.accordion .text ul {
    margin-top: 20px;
    margin-bottom: 43px
}

.accordion .h4,
.accordion .h5,
.accordion .h6,
.accordion h4,
.accordion h5,
.accordion h6 {
    margin-top: 31px
}

.accordion .h4+ol,
.accordion .h4+ul,
.accordion .h5+ol,
.accordion .h5+ul,
.accordion .h6+ol,
.accordion .h6+ul,
.accordion h4+ol,
.accordion h4+ul,
.accordion h5+ol,
.accordion h5+ul,
.accordion h6+ol,
.accordion h6+ul {
    margin-top: -18px!important
}

.accordion li:before {
    top: 0;
    background: url(./svg/bullet.svg) no-repeat center center;
    width: 20px;
    height: 20px
}

.accordion .banner {
    position: relative;
    max-height: 385px;
    min-height: 274px;
    overflow: hidden;
    margin: 0 -103px 58px
}

.accordion .banner:before {
    content: '';
    padding-top: 33.5%;
    display: block
}

.accordion .banner img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.accordion ul li {
    padding-left: 40px
}

.accordion ul li:before {
    top: 5px;
    background: url(./svg/bullet.svg) no-repeat center center;
    width: 23px;
    height: 20px
}

@media screen and (max-width:840px) {
    .accordion .top h5 {
        font-size: 18px
    }
    .accordion .top {
        padding-left: 20px;
        padding-right: 20px;
        min-height: 93px
    }
    .accordion .top p {
        font-size: 14px
    }
    .accordion {
        width: auto;
        min-width: 100%;
        margin-bottom: 8px
    }
    .accordion .top .arrow {
        right: 13px
    }
    .accordion .content {
        padding-top: 0
    }
    .accordion .content {
        padding-left: 20px!important;
        padding-right: 20px!important
    }
    .accordion .leftColumn {
        margin-bottom: 56px
    }
    .accordion .leftColumn,
    .accordion .rightColumn {
        width: 100%;
        padding-right: 0;
        padding-left: 0
    }
    .accordion .h3,
    .accordion h3 {
        margin-bottom: 22px
    }
    .accordion .h4,
    .accordion .h5,
    .accordion .h6,
    .accordion h4,
    .accordion h5,
    .accordion h6 {
        margin-top: 42px
    }
    .accordion ul li {
        padding-left: 35px
    }
    .accordion .text li,
    .accordion .text ul {
        margin-bottom: 24px
    }
    .accordion .text ol,
    .accordion .text ul {
        margin-bottom: 55px
    }
    .accordion .content {
        padding-bottom: 73px
    }
    .careerForm {
        margin-top: 48px
    }
    .accordion.open {
        margin-bottom: 40px
    }
    .accordion .banner {
        margin-left: -20px!important;
        margin-right: -20px!important;
        margin-bottom: 50px
    }
}

.careesBlock .container>*,
.pageNumber,
.projectSlider,
.scrollDown,
.titleRow>* {
    opacity: 0
}
/*
.accordionsWrap .accordion,
*/
.articleBanner,
.articleContent>*,
.contactsDataItem,
.coreListitem,
.coreValues .container>*,
.ethosText,
.firstScreen .content>*,
.imagesSlider,
.positionsTitleRow,
.privacyContent,
.projectsInspire .container>.text,
.projectsInspireItem,
.teamGrid,
.timelineVision .text,
.titleRow+div {
    opacity: 0
}

.projectsInspire .container>.text.aos-animate {
    opacity: 1
}

@media screen and (max-width:840px) {
    .articleNavigationItem .titleRow .titleSVG.noMargin {
        -webkit-transform: none!important;
        -ms-transform: none!important;
        transform: none!important
    }
    .projectSinglePage .pageNumber,
    .projectSinglePage .titleRow+.row,
    .projectSinglePage .titleSVG {
        -webkit-transform: none!important;
        -ms-transform: none!important;
        transform: none!important
    }
}

.popup_bg {
    position: fixed!important;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 280;
    display: none;
    pointer-events: initial
}

.popup_bg.dark {
    background-color: rgba(0, 0, 0, .6)
}

.popup_wrap {
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 281;
    pointer-events: none
}

.popup_block {
    pointer-events: initial;
    overflow: hidden;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 49.6vw;
    margin: auto;
    z-index: 281;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    min-width: 550px;
    will-change: transform
}

.popup_block:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #6463f8;
    opacity: .88;
    z-index: -1
}

.popup_block .bottom .main_btn {
    margin-right: 15px
}

.popup_block .bottom .main_btn:last-child {
    margin: 0
}

.popup_block input,
.popup_block textarea {
    background: 0 0
}

.popup_block.gray {
    background: #f7f8f8
}

.popup_block * {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.popup_block .content_wrap_inner {
    max-height: 100%
}

.popup_block .content_wrap {
    padding: 30px 30px 20px;
    max-height: 100%;
    text-align: center
}

.popup_block h2 {
    font-size: 27px;
    margin-bottom: 13px
}

.popup_block p.title {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: .23px
}

.popup_block .content {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px
}

.popup_block .content,
.popup_block .content_wrap {
    position: relative;
    z-index: 1
}

.popup_block .close {
    position: absolute;
    right: 107px;
    cursor: pointer;
    min-width: initial;
    width: auto;
    height: 73px;
    top: 0;
    font-family: "Jasan Wide", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding-top: 5px
}

.popup_block .close span {
    margin-right: 12px
}

.popup_block .close svg {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.popup_block.done img.done {
    margin-top: 16px;
    margin-bottom: 35px
}

.popup_block.done p {
    font-size: 18px
}

.popup_block .popupWrap {
    overflow: hidden;
    height: 100vh;
    padding: 76px 0 0
}

.popup_block .popupWrap.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center
}

.popup_block .popupWrap .popupContent {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    width: 100%;
    max-width: initial!important
}

.popup_block .popupWrap>.contacts {
    min-height: 100vh
}

.popup_block .h2,
.popup_block h2 {
    font-family: Monument Extended, serif;
    font-size: 36px;
    line-height: 135%;
    font-weight: 400;
    margin-bottom: 8px
}

.popup_block p {
    font-family: Formular, serif;
    font-size: 18px;
    line-height: 22px;
    color: #1a1a1a;
    opacity: .5
}

.popup_block.fullWidth {
    width: 100%
}

.popupContent {
    width: 339px;
    max-width: 100%;
    padding-top: 40px;
    margin: auto
}

.popupContent .bottom {
    margin-top: 61px
}

.popupContent .main_btn {
    min-width: 119px
}

form.contacts {
    margin-top: 50px
}

.border-title{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 82px;
    color:#fff;
    text-shadow:
            -1px -1px 0 #e2e3e9,
            1px -1px 0 #e2e3e9,
            -1px 1px 0 #e2e3e9,
            1px 1px 0 #e2e3e9;
    text-transform:uppercase;
}

.border-title2 {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 82px;
    color: #192a58;
    text-shadow:
            -1px -1px 0 #fff,
            1px -1px 0 #fff,
            -1px 1px 0 #fff,
            1px 1px 0 #fff;
    text-transform:uppercase;
}

.border-title3 {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    color: #16224e;
    text-shadow:
            -1px -1px 0 #fff,
            1px -1px 0 #fff,
            -1px 1px 0 #fff,
            1px 1px 0 #fff;
    text-transform:uppercase;
}

.border-title3 {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    color: #16224e;
    text-shadow:
            -1px -1px 0 #fff,
            1px -1px 0 #fff,
            -1px 1px 0 #fff,
            1px 1px 0 #fff;
    text-transform:uppercase;
}

.border-title4 {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    color: #f67c1f;
    text-shadow:
            -1px -1px 0 #fff,
            1px -1px 0 #fff,
            -1px 1px 0 #fff,
            1px 1px 0 #fff;
    text-transform:uppercase;
}

.border-title0{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    color:#fff;
    text-shadow:
            -1px -1px 0 #e2e3e9,
            1px -1px 0 #e2e3e9,
            -1px 1px 0 #e2e3e9,
            1px 1px 0 #e2e3e9;
    text-transform:uppercase;
}

.border-title11{
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 58px;
    color:#fff;
    text-shadow:
            -1px -1px 0 #e2e3e9,
            1px -1px 0 #e2e3e9,
            -1px 1px 0 #e2e3e9,
            1px 1px 0 #e2e3e9;
    text-transform:uppercase;
}
span.wpcf7-list-item {
    margin: 0 0 0 0;
}
input[type="checkbox"]{
    width:16px;
    height:16px;
    display:inline-block;
    padding:0;
    position:relative;
    top:5px;
}

input:checked {
    border: 2px solid #f67c1f;
    background:url(./png/cheack.png) center center no-repeat;
}

.coreValues p{
    font-size:16px;
}

.wpcf7-list-item.last{
    font-family: Montserrat, serif;
    color: #16224e;
    font-size: 18px;
    margin-bottom:20px;
}
.contactForm p {
    margin-bottom:0px;
}

.clear_filter {
    display: inline-block;
    position: relative;
    line-height: 26px;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding-right: 0px;
    padding-left:25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background:url(./jpg/closers.jpg) left 5px no-repeat;
    transition:0.3s ease;
}
.clear_filter:hover,
.clear_filter:focus{
    color:#f67c1f;
    cursor:pointer;
}

.clear_filter_3{
    text-align: center;
    width: 115px;
    display: none;
    margin: 30px auto 0;
}
.fiil_hide{
    display:none;
    width: -webkit-calc(100% + 12px * 2);
    width: calc(100% + 12px * 2);
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 30px;
}

.padding_top{
    width:100%;
    height:48px;
    display:block;
}

.opened-position__place {
    display: none;
}

.cursor {
    cursor: pointer;
}

@media (max-width: 675px) {
    .opened-position__short-name {
        display: none;
    }

    .opened-position__place {
        display: inline-block;
    }
}