.aboutBlock {
    margin-bottom: 123px;
}

.aboutBlock .aos span {
    display: block;
    margin-bottom: 5px;
}

.aboutSVG {
    position: absolute;
    left: -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50%;
}

.aboutSVG.about-block svg {
    height: 63%!important;
}
.contactLottie.contact-block svg {
    top: -25%!important;
}

.aboutSVG:before, .contactLottie:before {
    content: '';
    padding-top: 86%;
    display: block;
}

.aboutSVG svg, .contactLottie svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
}

.aboutBlock .row {
    padding-top: 57px;
}

.aboutBlock .title-SVG {
    position: absolute;
    margin-top: 29px;
}

.title-SVG span, .title-SVG a {
    position: absolute;
    top: 46%;
    right: -64px;
    transform: translateY(-50%);
    z-index: 9999;
}

@media screen and (min-width: 1280px) {
    .main-page .aboutBlock.fullHeight .titleSVG svg {
        height: 83.45px;
        width: auto;
    }
}

@media screen and (max-width: 991px) {
    .aboutBlock.fullHeight {
        padding-bottom: 0;
        padding-top: 0;
    }
}

@media screen and (max-width: 840px) {
    .aboutBlock {
        margin-bottom: 24px;
        display: block;
        padding-top: 0;
    }
    .fullHeight {
        min-height: initial;
    }
    .aboutSVG {
        display: none;
        position: relative;
        width: 700px;
        top: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        height: auto;
        max-width: 124vw;
        left: -35%;
        margin-top: -6%;
        margin-bottom: -22%;
    }
    .aboutBlock .container {
        padding-top: 0;
    }
    .aboutSVG svg {
        width: 215%!important;
        left: -77%!important;
        top: -30%!important;
        height: 115%!important;
    }
}

@media screen and (max-width: 768px) {
    .aboutBlock .titleSVG svg, .careesBlock .titleSVG svg, .projectsBlock .titleSVG svg {
        height: 49px;
        left: 0;
    }
}


