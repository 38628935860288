.main-page .projectsBlock {
    margin-bottom: 120px;
}

.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.projectSlider .controllRow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 40px;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    transition-property: transform,-webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    transition-property: transform,-webkit-transform;
}

.projectSliderItem {
    position: relative;
    width: 474px;
    height: 488px;
    overflow: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    background: rgba(0, 0, 0, .25);
}

.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
     list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    background: #000;
    opacity: .2;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid #16224e;
    background: 0 0;
    opacity: 1;
    margin: 0 10px;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    cursor: pointer;
}

.swiper-pagination-bullet-active {
    background: #16224e;
}

.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
}

.swiper-button-next, .swiper-button-prev {
    position: relative;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
}

.swiper-button-next svg, .swiper-button-prev svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.swiper-button-prev svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.projectSliderItem .content {
    position: absolute;
    left: 0;
    right: 0;
    top: -webkit-calc(100% - 127px);
    top: calc(100% - 127px);
    z-index: 2;
    padding: 32px 32px 39px;
    -webkit-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    -o-transition: all .6s cubic-bezier(.785, .135, .15, .86);
    transition: all .6s cubic-bezier(.785, .135, .15, .86);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 127px;
    text-decoration: none;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.projectSliderItem .content:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(246, 124, 31, .8);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    z-index: -1;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    will-change: left, top, right, bottom;
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.projectSliderItem .topRow {
    margin-top: 38px;
}

.projectSliderItem .topRow {
    height: 0;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    position: relative;
    padding-right: 50px;
}

.projectSliderItem .mainRow {
    position: relative;
    padding-left: 87px;
}

.projectSliderItem ul {
    color: #fff;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #16224e;
    list-style: none;
    padding-left: 39px;
    padding-top: 24px;
    padding-bottom: 2px;
}

.projectSliderItem ul {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
}

.projectSliderItem ul li {
    position: relative;
    padding-left: 48px;
    margin-bottom: 14px;
}

.projectSliderItem ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 16px;
    height: 2px;
    background: #fff;
}

.projectSliderItem .topRow:after {
    content: '';
    position: absolute;
    right: 13px;
    top: 24%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: url(../../../svg/arrow_link_white.svg) no-repeat center center;
}

.projectSliderItem.active .content, .projectSliderItem:hover .content {
    top: 0;
    height: 100%;
}

.projectSliderItem.active .topRow, .projectSliderItem:hover .topRow {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 38px;
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}

.swiper-pagination-bullet:hover {
    border-color: #f67c1f;
    background: 0 0
}

.swiper-button-next:hover svg path,
.swiper-button-prev:hover svg path {
    fill: #f67c1f
}

.teamSliderItem:hover .content:after {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.swiper-slide .content {
    cursor: pointer;
}

@media screen and (max-width:800px) {
    .swiper-pagination-bullet {
        margin: 0 3px;
    }

    .contactLottie.lottie.shape.animated.contact-block {
        display: none;
    }
}

@media (max-width: 400px) {
    .projectsBlock {
        margin-bottom: 50px !important;
    }

    .controllRow {
        margin-top: 0px !important;
        margin-bottom: 37px;
    }

    .line_hover.type2.arrow.bigPadding {
        font-size: 16px;
    }

    .titleRow .titleSVG a{
        top: 40% !important;
    }

    .careers-block__logo > svg {
        width: 130px;
        height: 60px;
    }

    .contactsBlock {
        margin-top: 57px !important;
    }

    .scrollTopBtn {
        position: relative;
        margin: 0 auto;
        left: 0;
        transform: translateX(0);
    }

    footer .logo {
        padding-right: 0 !important;
        margin-left: 0 !important;
        transform: translateX(-50%) !important;
    }

    footer nav {
        padding-top: 70px;
    }

    footer li {
        margin-bottom: 6px;
    }

    footer li a {
        font-size: 16px !important;
    }

    #menu-footer-left {
        margin-bottom: 6px;
    }

    .wrapper .container:first-child {
        padding-top: 72px;
    }

    .row .w50 {
        width: 100%;
    }

    .projectsInspireWrap.row {
        margin: 55px 0 0 0!important;
    }

    .projectsInspireWrap.row li {
        font-size: 16px;
    }

    .articleBanner.aos.aos-init.aos-animate {
        margin-top: 50px;
    }
}