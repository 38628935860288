.members {
  //margin: 0 0 500px 0;
  padding: 0 0 50px 0;

  &__logos {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transform: scale(1.5);
  }
}