.project-inspire {
  &__footer {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.project-block {
  display: grid;
  grid-template-rows: 1fr 4fr;
}