.directions {
    &__bordered-text {
        font-family: Montserrat, serif;
        font-size: 4rem;
        font-weight: 900;
        color: transparent;
        -webkit-text-stroke: 1px rgb(208, 210, 219);
    }

    &-info {
        & .titleSVG {
            & > svg {
                position: absolute;
                top: 50%;
                right: -10%;
                transform: translateY(-50%);
            }
        }
    }
}

.titleSVG {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media screen and (min-width: 1280px) {
    .wrapper.projectsPage .main .titleSVG svg {
        height: 97.8px;
        max-width: none;
    }
}

@media (max-width: 410px) {
    .directions {
        &-info h2{
            max-width: 100%;
        }

        & .coreValues .container {
            padding-top: 0 !important;
        }
    }
}
